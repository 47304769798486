import React, { useState } from "react";
import "./TestsContentComponent.scss";
import TestReport from "./testReport/TestReport";
import TopicWiseAnalysis from "./topicWiseAnalysis/TopicWiseAnalysis";
import QuestionWiseAnalysis from "./questionWiseAnalysis/QuestionWiseAnalysis";
import TopperComparision from "./topperComparision/TopperComparision";
import PredictCollege from "./predictCollege/PredictCollege";
import { useHistory } from "react-router-dom";
import images from "../../../utilities/images/images";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const TestsContentComponent = ({ loginFromParent }) => {
  let location = useLocation();

  let testDate = location.state.testDate;

  let history = useHistory();

  const handleRedirect = () => {
    history.push({
      pathname: "/student-report-pdf",
    });
  };

  const handleRedirect2 = () => {
    if (loginFromParent) {
      history.push({
        pathname: "/parent-dashboard",
      });
    } else {
      sessionStorage.setItem("activeTab", "individual_test");
      history.push({
        pathname: "/user_analytics",
      });
    }
  };

  const [activeTab, setActiveTab] = useState("tr"); // Set the initial active tab to "tr"

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const redirectToPredictCollege = (Rank) => {
    setActiveTab("pc");
  };

  return (
    <div className="testsContentWrapper">
      <div className="testsContentInrWrapper">
        <div className="container">
          <div className="testsHeader">
            <div className="left">
              <div className="backBtn" onClick={handleRedirect2}>
                <img src={images.m2_backBtn} alt="backBtn" />
              </div>
              <div className="section-heading">RBTS Full Test</div>
            </div>
            <div className="right">
              <div className="date">{testDate}</div>
              <div className="downloadReportBtn">
                <button>
                  <span className="text" onClick={handleRedirect}>
                    Download report
                  </span>
                  <span>
                    <div className="img">
                      <img src={images.user_activity_48_1} alt="img" />
                    </div>
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className="tests_contentWpr">
            <div className="testsContentTabBox">
              <div className="testsContentTab">
                <ul
                  className="nav nav-tabs testsContentUl"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        activeTab === "tr" ? "nav-link active" : "nav-link"
                      }
                      onClick={() => handleTabClick("tr")}
                      id="tr-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tr"
                      type="button"
                      role="tab"
                      aria-controls="tr"
                      aria-selected="true"
                    >
                      Test report
                    </button>
                  </li>

                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        activeTab === "qwa" ? "nav-link active" : "nav-link"
                      }
                      onClick={() => handleTabClick("qwa")}
                      id="qwa-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#qwa"
                      type="button"
                      role="tab"
                      aria-controls="qwa"
                      aria-selected="false"
                    >
                      Question wise analysis
                    </button>
                  </li>

                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        activeTab === "twa" ? "nav-link active" : "nav-link"
                      }
                      onClick={() => handleTabClick("twa")}
                      id="twa-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#twa"
                      type="button"
                      role="tab"
                      aria-controls="twa"
                      aria-selected="false"
                    >
                      Topic wise analysis
                    </button>
                  </li>

                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        activeTab === "tc" ? "nav-link active" : "nav-link"
                      }
                      onClick={() => handleTabClick("tc")}
                      id="tc-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tc"
                      type="button"
                      role="tab"
                      aria-controls="tc"
                      aria-selected="false"
                    >
                      Topper Comparision
                    </button>
                  </li>

                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        activeTab === "pc" ? "nav-link active" : "nav-link"
                      }
                      onClick={() => handleTabClick("pc")}
                      id="pc-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#pc"
                      type="button"
                      ata
                      role="tab"
                      aria-controls="pc"
                      aria-selected="false"
                    >
                      Predict College
                    </button>
                  </li>
                </ul>
              </div>

              <div className="testsTabContent">
                <div className="tab-content" id="myTabContent">
                  {activeTab === "tr" && (
                    <div
                      className={
                        activeTab === "tr"
                          ? "tab-pane fade show active"
                          : "tab-pane fade"
                      }
                      id="tr"
                      role="tabpanel"
                      aria-labelledby="tr-tab"
                    >
                      <TestReport
                        redirectToPredictCollege={redirectToPredictCollege}
                      />
                    </div>
                  )}

                  {activeTab === "qwa" && (
                    <div
                      className={
                        activeTab === "qwa"
                          ? "tab-pane fade show active"
                          : "tab-pane fade"
                      }
                      id="qwa"
                      role="tabpanel"
                      aria-labelledby="qwa-tab"
                    >
                      <QuestionWiseAnalysis />
                    </div>
                  )}

                  {activeTab === "twa" && (
                    <div
                      className={
                        activeTab === "twa"
                          ? "tab-pane fade show active"
                          : "tab-pane fade"
                      }
                      id="twa"
                      role="tabpanel"
                      aria-labelledby="twa"
                    >
                      <TopicWiseAnalysis />
                    </div>
                  )}

                  {activeTab === "tc" && (
                    <div
                      className={
                        activeTab === "tc"
                          ? "tab-pane fade show active"
                          : "tab-pane fade"
                      }
                      id="tc"
                      role="tabpanel"
                      aria-labelledby="tc"
                    >
                      <TopperComparision />
                    </div>
                  )}

                  {activeTab === "pc" && (
                    <div
                      className={
                        activeTab === "pc"
                          ? "tab-pane fade show active"
                          : "tab-pane fade"
                      }
                      id="pc"
                      role="tabpanel"
                      aria-labelledby="pc"
                    >
                      <PredictCollege />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestsContentComponent;
