import { Fragment, useEffect, useState } from "react";
import "./user_store_content_component.scss";

//////Component List//////////
import PackageOfferingComponent from "./store_components/package_offering_component/package_offering_component";
import PackageDetailsComponent from "./store_components/package_details_component/package_details_component";
import HandBooks from "./handBooks/HandBooks";

import axios from "axios";
import { sha512 } from "js-sha512";

import { Analytics } from "aws-amplify";
import {
  setStoreCurrentPackageDetails,
  setRefferalCode,
} from "../../../../redux/packages_store/PackageSlice";
import { setPackageDetailsForPayment } from "../../../../redux/payment_gateway/action";
// import userProductList from "../../../../redux/new_store/action";
import userProductList from "../../../../redux/new_store/action";
import {
  url_8,
  url_16,
  url_19,
  url_56,
  url_176,
  url_217,
} from "../../../../custom_utilities/api_services";

import jsSHA from "jssha";
//Redux
import { connect } from "react-redux";
import uiActions from "../../../../redux/ui/action";
import authActions from "../../../../redux/auth/action";
import images from "../../../../utilities/images/images";
import { useLocation } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";
import { toastifyMessage } from "../../../../custom_utilities/toastify_config";

const UserStoreContentComponent = (props) => {
  let {
    history,
    userData,
    userPreference,
    startLoading,
    finishLoading,
    setUser,
    setStoreCurrentPackageDetails,
    setRefferalCode,
    setPackageDetailsForPayment,
    orderId,
    token,
    tokenType,
    user_id,
    loginType,
    // pageEqualToStorecourseDetails,
    setLoginType,
  } = props;

  console.log("testing userData orderId", orderId);

  const pageEqualToStorecourseDetails1 = {
    active: 1,
    advantages: null,
    allow_installment: "Yes",
    amount: "2199.00",
    batch: [],
    batch_id: null,
    bg_image: "/storage/package/course_banner/381.png",
    coin_discount_amt: "1099.00",
    course: "JEE 12th English",
    course_benifits:
      "Text and video solutions,Topic-wise tests,100+ Practice papers",
    course_id: 13,
    created_on: "2023-12-16 13:39:06",
    description: `Features:
    90+ Past year papers,
    34 Subtopic wise Minor test,
    9 Major test,
    17 Full Syllabus test,
    5 BITSAT test,
    3 WBJEE test,
    2 IAT test,
    One-stop solution for all engineering exams,
    Video and text solutions of each test,
    Detailed report and analysis after every test,
    Created and crafted by expert Kota faculties as per latest updated syllabus,
    Validity till JEE Advanced 2025,
    Use 50% motion coins for an extra discount.`,
    discount: "0.00",
    duration: "12 months",
    expiry_date: "2025-07-05",
    final_amount: "2199",
    icon:
      "https://learning.motion.ac.in/motioneducation//storage/package/app_icon.png",
    is_amirt: 0,
    is_delete: 0,
    is_referral: null,
    is_refundable: null,
    is_subscribe: false,
    package_id: 381,
    package_include_module: [
      { module: "Live Classess" },
      { module: "Video" },
      { module: "Zoom Classess" },
    ],
    package_name: "Motion Targeted Abhyas (MAT) - Phase 1.0",
    package_sequence: null,
    package_tag: "2",
    package_thumbnail: "/storage/package/thumbnail/381.png",
    package_type: 3,
    tags: null,
    txn_expire_date: "",
    u_programId: null,
    video_url: null,
    web_thumbnail: "/storage/package/web_thumbnail/381.png",
  };

  let location = useLocation();
  let tab = location?.state?.tab?.length
    ? location.state.tab
    : "online_learning";

  const [state, setState] = useState({
    step: 1,
    userPrefernces: {
      courseId: "",
      classId: "",
      batchId: "",
    },
    packagesCollection: {},
    packageDetails: {},
    facultyList: {},
    subjectList: {},
    facultyDetail: {},
    freePackagesCollection: {},
    couponDetails: {},
    isCourseId: false,
    selectPackageId: "",
    selectPackageAmt: "",
    coinData: {},
    coupanApplied: 0, // 0 means not applied, 1 means applied before others
    showBanner: true,
    allPackagesCollection: {},
  });

  useEffect(() => {
    // if (loginType === "store") {

    if (loginType !== "dashboard") learnMoreBtn(pageEqualToStorecourseDetails1);
    setLoginType("dashboard");
    // }
  }, []);

  const getAllPackagesCollection = async () => {
    startLoading();
    try {
      const response = await axios.get(`${url_217}`);

      if (response.data.status === 200) {
        finishLoading();
        console.log(response.data.data);
        setState((prevState) => ({
          ...prevState,
          allPackagesCollection: response.data.data,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  useEffect(() => {
    getUserPreferences();
    getAllPackagesCollection();
  }, []);

  useEffect(() => {
    if (state.isCourseId) {
      getPackagesCollection();
      getFreePackagesCollection();
    }
  }, [state.userPrefernces.courseId]);

  const currentData = new Date();
  let date =
    currentData.getFullYear() +
    "-" +
    (currentData.getMonth() + 1) +
    "-" +
    currentData.getDate();
  let time =
    currentData.getHours() +
    ":" +
    currentData.getMinutes() +
    ":" +
    currentData.getSeconds();
  var dateTime = date + " " + time;
  const getUserPreferences = () => {
    setState((prevState) => ({
      ...prevState,
      userPrefernces: {
        ...prevState.userPrefernces,
        courseId: userPreference.courseDetails[0].course_id,
        classId: userPreference.classDetails.class_id,
        batchId: userPreference.batchDetails.batch_id,
      },
      isCourseId: true,
    }));
  };

  const getPackagesCollection = async () => {
    let userPrefernces = { ...state.userPrefernces };
    startLoading();
    try {
      const response = await axios.get(
        `${url_16}?course_id=${userPrefernces.courseId}&batch_id=${userPrefernces.batchId}&source="Store"`
      );

      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          packagesCollection: response.data.data,
          isCourseId: false,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };
  const getFreePackagesCollection = async () => {
    // let userPrefernces = { ...state.userPrefernces };
    startLoading();
    let data = {
      search: "",
      target_id: userPreference.targetDetails[0].target_id,
    };

    try {
      const response = await axios.post(url_56, data);

      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          freePackagesCollection: response.data.data,
          isCourseId: false,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleStepNavigation = (stepValue, showBannerVal = false) => {
    setState((prevState) => ({
      ...prevState,
      step: stepValue,
      showBanner: showBannerVal,
    }));
  };

  const learnMoreBtn = async (packageDetails) => {
    setRefferalCode(" ");
    setState((prevState) => ({
      ...prevState,
      showBanner: false,
    }));
    Analytics.record({
      name: "web_product_overview_page",
      attributes: {
        user_id: userData.user_id,
        phone_number: userData.mobile,
        course_id: packageDetails?.course_id,
        course_name: packageDetails?.course,
        package_id: packageDetails?.package_id,
        package_name: packageDetails?.package_name,
      },
    });

    setState((prevState) => ({
      ...prevState,
      packageDetails: packageDetails,
    }));

    setStoreCurrentPackageDetails(packageDetails);
    // handleStepNavigation(3);
    history.push(`/package/${packageDetails?.package_id}`);
  };

  const HMAC = (message, key) => {
    var hmac = "";
    var shaObj = new jsSHA("SHA-256", "TEXT");
    shaObj.setHMACKey(key, "HEX");
    shaObj.update(message);
    hmac = shaObj.getHMAC("HEX");
    // var hash = sha256.hmac.create(key);
    //     hash.update(message);
    //     hash.hex();
    // return hash
    return hmac;
  };

  const activePackage = async (data) => {
    let packageDetails = { ...state.packageDetails };
    let userPreferences = { ...state.userPrefernces };
    let dataDetails = {
      course_id: userPreferences.courseId,
      batch_id: userPreferences.batchId,
      package_id: data.package_id,
      txn_amount: packageDetails.final_amount,
      //txn_discount_amount: packageDetails.discount,
      txn_amount: "0.00",
      //txn_discount_amount: "",
      txn_date: dateTime,
      package_duration: data.duration,
      txnid: state.couponDetails.CouponCode,
    };

    // console.log(dataDetails);
    startLoading();
    try {
      const response = await axios.post(url_19, dataDetails);
      if (response.data.status === 200) {
        finishLoading();
        // alert(response.data.message);
        //let returnedResponse = await updateUser();
        history.push("/user_dashboard");
        // if (returnedResponse) {
        // } else {
        //   history.push("/user_dashboard");
        // }
      } else {
        alert(response.message);
      }
      finishLoading();
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const updateUser = async () => {
    startLoading();
    try {
      const response = await axios.get(url_8);
      if (response.data.status === 200) {
        setUser(response.data.data);
        finishLoading();
        return 1;
      } else {
        return 0;
      }
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const renderStepComponent = () => {
    let {
      step,

      packageDetails,

      allPackagesCollection,
    } = state;

    switch (step) {
      case 1:
        return (
          <PackageOfferingComponent
            learnMoreBtn={learnMoreBtn}
            allPackagesCollection={allPackagesCollection}
          />
        );

      case 3:
        return (
          <PackageDetailsComponent
            handleStepNavigation={handleStepNavigation}
            packageDetails={packageDetails}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <div className="userStoreContentComponent_wrapper">
        <div className="userStoreContentComponent_inner_wrapper">
          <div className="sec_2_wrapper">
            <div className="container">
              {state.showBanner && (
                <div className="new_wallet_wrapper">
                  <Row>
                    <Col lg={1} md={12} sm={12}>
                      <img
                        className="wallet_image"
                        src={images.motionWalletNew}
                        alt="wallet"
                      />
                    </Col>
                    <Col className="d-flex align-items-center">
                      <div className="coinsBannerText">
                        <p>Total Motion coins :</p>
                        <img src={images.motionCoin} width={25} alt="coin" />
                        <span>{state.coinData.current_coins}</span>
                      </div>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-center">
                      <Link className="coins_history" to="/wallet">
                        View Coins History
                      </Link>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </div>
          <div className="tab_container">
            <div className="container">
              <div className="tabBox">
                <div className="tabcontent">
                  {state.showBanner && (
                    <div className="tab">
                      <ul
                        className="nav nav-tabs onlineLearning_handBook_Ul"
                        id="myTab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className={
                              tab == "online_learning"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            id="online_learning-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#online_learning"
                            type="button"
                            role="tab"
                            aria-controls="online_learning"
                            aria-selected="false"
                          >
                            Online Learning
                          </button>
                        </li>

                        <li className="nav-item" role="presentation">
                          <button
                            className={
                              tab == "hand_books"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            id="hand_books-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#hand_books"
                            type="button"
                            role="tab"
                            aria-controls="hand_books"
                            aria-selected="false"
                          >
                            Hand Books
                          </button>
                        </li>
                      </ul>
                    </div>
                  )}
                  <div className="ol_hb_TabContent">
                    <div className="tab-content" id="myTabContent">
                      <div
                        className={
                          tab == "online_learning"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="online_learning"
                        role="tabpanel"
                        aria-labelledby="online_learning-tab"
                      >
                        <div className="course_packge">
                          <div className="container" style={{ padding: "0" }}>
                            {renderStepComponent()}
                          </div>
                        </div>
                      </div>

                      <div
                        className={
                          tab == "hand_books"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="hand_books"
                        role="tabpanel"
                        aria-labelledby="hand_books-tab"
                      >
                        <HandBooks />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    orderId: state.paymentReducer.orderId,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
    user_id: state.auth.user.user_id,
    loginType: state.userStore.loginType,
    // pageEqualToStorecourseDetails:
    //   state.userStore.pageEqualToStorecourseDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setUser: (payload) => {
      dispatch(authActions.setUser(payload));
    },

    setStoreCurrentPackageDetails: (payload) => {
      dispatch(setStoreCurrentPackageDetails(payload));
    },

    setRefferalCode: (payload) => {
      dispatch(setRefferalCode(payload));
    },

    setPackageDetailsForPayment: (payload) => {
      dispatch(setPackageDetailsForPayment(payload));
    },
    setLoginType: (payload) => {
      dispatch(userProductList.setLoginType(payload));
    },

    setPackageDetailsForPayment,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserStoreContentComponent);
