import { Fragment, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  url_40,
  url_39,
} from "../../../../../../custom_utilities/api_services";
import axios from "axios";
import { connect } from "react-redux";
import BookmarkViewQuestionsView from "./bookmark_view_questions_view/bookmark_view_questions_view";
import uiActions from "../../../../../../redux/ui/action";

const BookmarkViewContentComponent = (props) => {
  let { history, startLoading, finishLoading, userData } = props;

  const [state, setState] = useState({
    step: 1,
    testSectionsDetails: {},
    testQuestionsDetails: [],
    currentQuestion: 0,
  });

  const location = useLocation();
  let type = location.state.type;

  useEffect(() => {
    getQuestionsList();
  }, []);

  const getQuestionsList = async () => {
    startLoading();
    let data;
    if (type === "dpp") {
      data = {
        type: 2,
      };
    } else if (type === "pyq") {
      data = {
        type: 6,
      };
    } else if (type === "exercise") {
      data = {
        type: 1,
      };
    }
    try {
      const response = await axios.post(url_40, data);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          testQuestionsDetails: response.data.data.AllQuestionList,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePreviousQuestionBtn = () => {
    getQuestionsList();
    let { currentQuestion, testQuestionsDetails } = state;
    if (currentQuestion >= 1) {
      setState((prevState) => ({
        ...prevState,
        currentQuestion: currentQuestion - 1,
        testQuestionsDetails: testQuestionsDetails,
      }));
      window.scrollTo(0, 0);
    }
  };

  const handleNextQuestionBtn = () => {
    getQuestionsList();
    let { currentQuestion, testQuestionsDetails } = state;
    if (testQuestionsDetails.length === currentQuestion + 1) {
      history.push("/user_bookmarks");
    } else {
      setState((prevState) => ({
        ...prevState,
        currentQuestion: currentQuestion + 1,
        testQuestionsDetails: testQuestionsDetails,
      }));
      window.scrollTo(0, 0);
    }
  };

  const handleQuestionJump = (questionNumber) => {
    // handleSaveQuestion();
    setState((prevState) => ({
      ...prevState,
      currentQuestion: questionNumber,
      // currentQuestion:0,
    }));
  };

  // const handleQuestionJump = (questionNumber) => {
  //   getQuestionsList();
  //   setState((prevState) => ({
  //     ...prevState,
  //     currentQuestion: questionNumber,
  //   }));
  // };

  const handleBookmarkQuestion = async (testQuestionDetails, type) => {
    const { testQuestionsDetails, currentQuestion } = state;
    const updatedTestQuestions = testQuestionsDetails.filter(
      (question) => question.qid !== testQuestionDetails.qid
    );

    setState((prevState) => ({
      ...prevState,
      testQuestionsDetails: updatedTestQuestions,
    }));

    let data = {
      paper_id: testQuestionDetails.paper_id,
      type: type, // 1 for test
      reference_id: testQuestionDetails.qid,
      status: 0, // 0 => unsave
    };

    try {
      const response = await axios.post(url_39, data);
      // Handle the response if needed
    } catch (error) {
      console.log(error);
    }

    if (currentQuestion < updatedTestQuestions.length) {
      // If there are remaining questions after removal, update the currentQuestion index
      setState((prevState) => ({
        ...prevState,
        currentQuestion: currentQuestion,
      }));
    } else if (
      currentQuestion === updatedTestQuestions.length &&
      currentQuestion > 0
    ) {
      // If the removed question was the last question, move to the previous question
      setState((prevState) => ({
        ...prevState,
        currentQuestion: currentQuestion - 1,
      }));
    } else {
      // If the removed question was the only question, redirect to the desired route
      history.push("/user_bookmarks");
    }

    // Pass the updated testQuestionsDetails back to the parent component
    handleUpdateTestQuestionDetails(updatedTestQuestions);
  };

  const handleUpdateTestQuestionDetails = (updatedDetails) => {
    setState((prevState) => ({
      ...prevState,
      testQuestionsDetails: updatedDetails,
    }));
  };

  const renderView = (step) => {
    switch (step) {
      case 1:
        return (
          <BookmarkViewQuestionsView
            testQuestionsDetails={state.testQuestionsDetails}
            subjecSectiontWiseTestQuestions={
              state.subjecSectiontWiseTestQuestions
            }
            currentQuestion={state.currentQuestion}
            countDownTimer={state.countDownTimer}
            handlePreviousQuestionBtn={handlePreviousQuestionBtn}
            handleNextQuestionBtn={handleNextQuestionBtn}
            handleQuestionJump={handleQuestionJump}
            // handleBookmarkDppQuestion={handleBookmarkDppQuestion}
            userId={userData.user_id}
            startLoading={startLoading}
            finishLoading={finishLoading}
            handleBookmarkQuestion={handleBookmarkQuestion}
            type={type}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Fragment>
      <div className="cps_test_content_component_wrapper">
        <div className="test_content_component_inner_wrapper">
          {renderView(state.step)}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userPreference: state.userPreference,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookmarkViewContentComponent);
