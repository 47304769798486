import { Fragment, useEffect, useState, useMemo } from "react";
import "./user_edit_profile_content_component.scss";

import images from "../../../../utilities/images/images";
import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";
import axios from "axios";
import { BASE_URL } from "../../../../custom_utilities/baseURL";
import {
  url_3,
  url_4,
  url_6,
  url_8,
  url_60,
} from "../../../../custom_utilities/api_services";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../redux/ui/action";
import authActions from "../../../../redux/auth/action";
import userPreferenceActions from "../../../../redux/user_preference/action";
import moment from "moment";
import RefundContainer from "../refund_modal/RefundContainer";
import {
  setCurrentRefundPackage,
  clearRefundPackage,
} from "../../../../redux/packages_store/refund/RefundPackageSlice";
import RefundSuccessModal from "../refund_modal/RefundSuccessModal";

const UserEditProfileContentComponent = (props) => {
  const [state, setState] = useState({
    userDetails: {
      profileImageDetails: {
        imageData: {},
        localImageURL: "",
        serverImageURL: "",
      },
      fullName: "",
      date_of_birth: "",
      roll_no: "",
      gender: "",
      email: "",
      email_valid: true,
      state: "",
      city: "",
      mobile_number: "",
      acps_pin: "",
      acps_pin_invalid: false,
    },
    states: {},
    cities: {},
    buyPackageList: {},
    editToggle: false,
    isRefundModalOpen: false,
    isRefundSuccessModalOpen: false,
  });
  let {
    history,
    userPreference,
    startLoading,
    finishLoading,
    setUser,
    setUserPreference,
    token,
    tokenType,
    currentRefundPackage,
    setCurrentRefundPackage,
    clearRefundPackage,
  } = props;

  useEffect(() => {
    getUserData();
    getStates();
    getBuypackage();
  }, []);

  const getBuypackage = async () => {
    startLoading();
    try {
      const response = await axios.get(url_60);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          buyPackageList: response.data.data,
        }));
      } else {
        finishLoading();
        alert(response.data.message);
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };
  const getUserData = async () => {
    try {
      const response = await axios.get(url_8);
      // const response = await axios.get(`${BASE_URL}/api/user/get`);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          userDetails: {
            ...prevState.userDetails,
            fullName: response.data.data.name,
            date_of_birth: response.data.data.dob,
            mobile_number: response.data.data.mobile,
            gender: response.data.data.gender,
            email: response.data.data.email,
            state: response.data.data.address.state,
            city: response.data.data.address.city,
            roll_no: response.data.data.roll_no,
            acps_pin: response.data.data.acps_pin,
            profileImageDetails: {
              ...prevState.userDetails.profileImageDetails,
              serverImageURL: response.data.data.image,
            },
          },
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStates = async () => {
    if (window.navigator.onLine) {
      try {
        const response = await axios.get(url_3);
        // const response = await axios.get(`${BASE_URL}/api/user/states`);

        if (response.data.status === 200) {
          setState((prevState) => ({
            ...prevState,
            states: response.data.data,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("Please check your internet connection");
    }
  };

  const getCities = async (selectedState) => {
    let state = selectedState;
    startLoading();
    try {
      const response = await axios.get(`${url_4}?state=${state}`);
      // const response = await axios.get(
      //   `${BASE_URL}/api/user/cities?state=${state}`
      // );

      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          cities: response.data.data,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const createImageLocalURL = (imageDetails) => {
    let objectURL = URL.createObjectURL(imageDetails);
    return objectURL;
  };

  const handleProfileImage = (event) => {
    let userDetails = { ...state.userDetails };
    let profileImageDetails = { ...userDetails.profileImageDetails };
    let { files } = event.target;
    let localImageURL = createImageLocalURL(files[0]);

    profileImageDetails.imageData = files[0];
    profileImageDetails.localImageURL = localImageURL;

    setState((prevState) => ({
      ...prevState,
      userDetails: {
        ...prevState.userDetails,
        profileImageDetails: profileImageDetails,
      },
    }));
  };

  const handleUpdateFormField = (event) => {
    let { name, value } = event.target;

    switch (name) {
      case "fullName":
        setState((prevState) => ({
          ...prevState,
          userDetails: {
            ...prevState.userDetails,
            fullName: value.replace(/[^A-Za-z ]/g, ""),
          },
        }));
        break;
      case "date_of_birth":
        setState((prevState) => ({
          ...prevState,
          userDetails: {
            ...prevState.userDetails,
            date_of_birth: value,
          },
        }));
        break;
      case "gender":
        setState((prevState) => ({
          ...prevState,
          userDetails: {
            ...prevState.userDetails,
            gender: value,
          },
        }));
        break;

      case "roll_no":
        setState((prevState) => ({
          ...prevState,
          userDetails: {
            ...prevState.userDetails,
            roll_no: value,
          },
        }));
        break;

      case "email":
        setState((prevState) => ({
          ...prevState,
          userDetails: {
            ...prevState.userDetails,
            email: value.replace(/[^A-Za-z0-9+-.~_@]/g, ""),
            email_valid: /^[A-Za-z0-9+_.-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
              value
            ),
          },
        }));
        break;
      case "acps_pin":
        setState((prevState) => ({
          ...prevState,
          userDetails: {
            ...prevState.userDetails,
            acps_pin: value.slice(0, 4),
            acps_pin_invalid:
              value.slice(0, 4).toString().length !== 4 ||
              value.slice(0, 4).toString() === "0000",
          },
        }));
        break;
      case "mobile_number":
        setState((prevState) => ({
          ...prevState,
          userDetails: {
            ...prevState.userDetails,
            mobile_number: value,
          },
        }));
        break;
      default:
        break;
    }
  };

  const handleStateDropdown = (event) => {
    let { value } = event.target;
    setState((prevState) => ({
      ...prevState,
      userDetails: { ...prevState.userDetails, state: value, city: "" },
    }));

    getCities(value);
  };
  const handleGenderDropdown = (event) => {
    let { value } = event.target;
    setState((prevState) => ({
      ...prevState,
      userDetails: { ...prevState.userDetails, gender: value },
    }));

    getCities(value);
  };

  const handleCityDropdown = (event) => {
    let { value } = event.target;

    setState((prevState) => ({
      ...prevState,
      userDetails: { ...prevState.userDetails, city: value },
    }));
  };

  const handleSubmitUpdateForm = async (event) => {
    event.preventDefault();
    let userDetails = { ...state.userDetails };

    if (window.navigator.onLine) {
      startLoading();
      let formData = new FormData();

      formData.append("name", userDetails.fullName);
      formData.append("dob", userDetails.date_of_birth);
      formData.append("gender", userDetails.gender);
      formData.append("roll_no", userDetails.roll_no);
      formData.append("state", userDetails.state);
      formData.append("mobile_number", userDetails.mobile_number);
      formData.append("city", userDetails.city);
      if (userDetails.email) {
        formData.append("email", userDetails.email);
      }

      if (userDetails.profileImageDetails.localImageURL.length) {
        formData.append("image", userDetails.profileImageDetails.imageData);
      }

      if (userDetails.acps_pin >= 1000) {
        formData.append("acps_pin", userDetails.acps_pin);
      }

      formData.append(
        "user_target_id",
        userPreference.targetDetails[0].target_id
      );
      let courseId = [userPreference.courseDetails[0].course_id];
      formData.append("target_ids", JSON.stringify(courseId)); /////Course Id

      formData.append("class_id", userPreference.classDetails.class_id);
      formData.append("batch_id", userPreference.batchDetails.batch_id);

      let returnedResponse = await updateProfileApi(formData);

      if (returnedResponse.result === 1) {
        finishLoading();
        setUser(returnedResponse.data.apiData);
        //////set user preference////////
        setUserPreference({
          targetDetails: returnedResponse.data.apiData.UsertargetDetail,
          courseDetails: returnedResponse.data.apiData.targets,
          classDetails: {
            class_id: returnedResponse.data.apiData.class_id,
            class: returnedResponse.data.apiData.class,
          },
          batchDetails: {
            batch_id: returnedResponse.data.apiData.batch_id,
            batch: returnedResponse.data.apiData.batch,
          },
        });
        // history.push("/user_profile");
      } else {
        finishLoading();
        alert(returnedResponse.data.message);
      }
    } else {
      alert("Please check your internet connection");
    }
  };

  const updateProfileApi = async (formData) => {
    try {
      const response = await axios.post(url_6, formData);
      // const response = await axios.post(
      //   `${BASE_URL}/api/user/signup`,
      //   formData
      // );

      if (response.status === 200) {
        if (response.data.status === 200) {
          return {
            result: 1,
            data: {
              message: response.data.message,
              apiData: response.data.data,
            },
          };
        } else {
          return { result: 0, data: { message: response.data.message } };
        }
      } else {
        return { result: 0, data: { message: "Something went wrong!!!" } };
      }
    } catch (error) {
      console.log(error.response);
      return {
        result: 0,
        data: { message: error.response.statusText },
      };
    }
  };
  const handleRedirect = () => {
    history.push("/user_dashboard");
  };

  const editButton = () => {
    setState((prevState) => ({
      ...prevState,
      editToggle: !prevState.editToggle,
    }));
  };

  const handleOpenRefundModal = () => {
    setState((prevState) => ({
      ...prevState,
      isRefundModalOpen: true,
    }));
  };

  const handleCloseRefundModal = () => {
    setState((prevState) => ({
      ...prevState,
      isRefundModalOpen: false,
    }));
  };

  const handleOpenRefundSuccessModal = () => {
    setState((prevState) => ({
      ...prevState,
      isRefundSuccessModalOpen: true,
    }));
  };

  const handleCloseRefundSuccessModal = () => {
    setState((prevState) => ({
      ...prevState,
      isRefundSuccessModalOpen: false,
    }));

    window.location.reload();
  };

  const refunduseMemo = useMemo(() => {
    return (
      <RefundContainer
        closeRefundModal={handleCloseRefundModal}
        isRefundModalOpen={state.isRefundModalOpen}
        startLoading={startLoading}
        finishLoading={finishLoading}
        token={token}
        tokenType={tokenType}
        currentRefundPackage={currentRefundPackage}
        openRefundSuccessModal={handleOpenRefundSuccessModal}
      />
    );
  }, [state.isRefundModalOpen]);

  const refundStatusClass = (pkg) => {
    const refundStatus = pkg.refund_status.toLowerCase();
    if (refundStatus === "request submitted") {
      return "refund_submitted";
    } else if (refundStatus === "refund initiated") {
      return "refundinitiated";
    } else if (refundStatus === "refund completed") {
      return "refundcompleted";
    } else if (refundStatus === "refund cancelled") {
      return "refundcancelled";
    } else {
      return "";
    }
  };

  return (
    <Fragment>
      <div className="userEditProfileContentComponent_wrapper">
        <div className="container">
          <div className="userEditProfileContentComponent_inner_wrapper">
            <div className="back_btn">
              <button onClick={() => handleRedirect()}>
                <img src={images.login_2} alt="" />
              </button>
            </div>
            <form onSubmit={(event) => handleSubmitUpdateForm(event)}>
              <div className="content_wrapper">
                <div className="image_content_wrapper">
                  <div className="image_wrapper">
                    <div className="image_inner_wrapper">
                      {state.userDetails.profileImageDetails.localImageURL
                        .length ? (
                        <img
                          className="dummy_image"
                          src={
                            state.userDetails.profileImageDetails.localImageURL
                          }
                        />
                      ) : state.userDetails.profileImageDetails.serverImageURL
                          .length ? (
                        <img
                          className="dummy_image"
                          src={
                            state.userDetails.profileImageDetails.serverImageURL
                          }
                        />
                      ) : (
                        <img className="dummy_image" src={images.signUp_1} />
                      )}

                      <label className="custom-file-upload">
                        <input
                          type="file"
                          name="profileImage"
                          id="upload_image"
                          accept="image/*"
                          onChange={(event) => handleProfileImage(event)}
                        />
                        {/* <img src={images.signUp_2} /> */}
                        <span>Replace</span>
                      </label>
                    </div>
                  </div>
                </div>
                <ul class="nav nav-tabs tab_button" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      Profile
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      My packages
                    </button>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="user_detail_box">
                      <div className="title_edit">
                        <h3>Personal information</h3>
                        <button type="button" onClick={editButton}>
                          <i class="far fa-edit"></i>
                        </button>
                      </div>
                      <div className="input_wrp_box">
                        <div className="input_wrapper">
                          <label for="fullName">Name</label>
                          <input
                            type="text"
                            name="fullName"
                            id="fullName"
                            value={state.userDetails.fullName}
                            placeholder="Name"
                            onChange={(event) => handleUpdateFormField(event)}
                            disabled={!state.editToggle}
                          />
                          <p
                            style={{ display: "none" }}
                            className={
                              state.userDetails.fullName || !state.editToggle
                                ? ``
                                : `error_text`
                            }
                          >
                            Please enter your name
                          </p>
                        </div>
                        <div className="input_wrapper">
                          <label for="mobile_number">Mobile</label>
                          <input
                            type="text"
                            name="mobile_number"
                            id="mobile_number"
                            value={state.userDetails.mobile_number}
                            onChange={(event) => handleUpdateFormField(event)}
                            placeholder="Mobile number"
                            disabled
                            // readOnly={true}
                          />
                        </div>
                      </div>
                      <div className="input_wrp_box">
                        <div className="input_wrapper">
                          <label for="date_of_birth">Date of Birth</label>
                          <input
                            type="date"
                            name="date_of_birth"
                            id="date_of_birth"
                            value={state.userDetails.date_of_birth}
                            placeholder="Date of Birth"
                            onChange={(event) => handleUpdateFormField(event)}
                            disabled={!state.editToggle}
                            required
                          />
                        </div>
                        <div className="input_wrapper">
                          <label for="email">Email id</label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            value={state.userDetails.email}
                            placeholder="Email id"
                            onChange={(event) => handleUpdateFormField(event)}
                            disabled={!state.editToggle}
                          />
                          <p
                            style={{ display: "none" }}
                            className={
                              state.userDetails.email_valid || !state.editToggle
                                ? ``
                                : `error_text`
                            }
                          >
                            Please enter a valid email
                          </p>
                        </div>
                      </div>
                      <div className="input_wrp_box">
                        <div className="select_wrapper">
                          <label for="state">State</label>
                          <select
                            id="state"
                            defaultValue={state.userDetails.state}
                            defaultChecked={state.userDetails.state}
                            onChange={(event) => handleStateDropdown(event)}
                            disabled={!state.editToggle}
                            // {/* // required */}
                          >
                            <option value="" disabled>
                              {state.userDetails.state
                                ? state.userDetails.state
                                : "Select State"}
                            </option>
                            {!isEmpty(state.states)
                              ? state.states.states.map((element, index) => {
                                  return (
                                    <option key={index} value={element}>
                                      {element}
                                    </option>
                                  );
                                })
                              : null}
                          </select>
                        </div>

                        <div className="select_wrapper">
                          <label for="city">City</label>
                          <select
                            id="city"
                            defaultValue={state.userDetails.city}
                            onChange={(event) => handleCityDropdown(event)}
                            disabled={!state.editToggle}
                            // required
                          >
                            <option value="" disabled>
                              {state.userDetails.city
                                ? state.userDetails.city.length
                                  ? state.userDetails.city
                                  : "Select City"
                                : "Select City"}
                            </option>
                            {!isEmpty(state.cities)
                              ? state.cities.districts.map((element, index) => {
                                  return (
                                    <option key={index} value={element}>
                                      {element}
                                    </option>
                                  );
                                })
                              : null}
                          </select>
                        </div>
                      </div>
                      <div className="input_wrp_box">
                        <div className="select_wrapper">
                          <label for="gender">Gender</label>
                          <select
                            id="gender"
                            value={state.userDetails.gender}
                            defaultChecked={state.userDetails.gender}
                            onChange={(event) => handleGenderDropdown(event)}
                            disabled={!state.editToggle}
                            // required
                          >
                            {/* <option value="" disabled>
                    {state.userDetails.state.length
                      ? state.userDetails.state
                      : "Select State"}
                  </option> */}

                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                        </div>

                        {/* <div className="select_wrapper">
                          <select
                            defaultValue={state.userDetails.state}
                            defaultChecked={state.userDetails.state}
                            onChange={(event) => handleStateDropdown(event)}
                            disabled={!state.editToggle} */}
                        {/* // required */}
                        {/* > */}
                        {/* <option value="" disabled>
                    {state.userDetails.state.length
                      ? state.userDetails.state
                      : "Select State"}
                  </option> */}
                        {/* {!isEmpty(state.states)
                              ? state.states.states.map((element, index) => {
                                  return (
                                    <option key={index} value={element}>
                                      {element}
                                    </option>
                                  );
                                })
                              : null}
                          </select>
                        </div> */}
                        <div className="input_wrapper">
                          <label for="acps_pin">ACPS pin</label>
                          <input
                            type="number"
                            max={9999}
                            name="acps_pin"
                            id="acps_pin"
                            value={state.userDetails.acps_pin}
                            placeholder="ACPS pin"
                            onChange={(event) => handleUpdateFormField(event)}
                            disabled={!state.editToggle}
                          />
                          <p
                            className={
                              state.userDetails.acps_pin_invalid
                                ? `error_text`
                                : ``
                            }
                            style={{ display: "none" }}
                          >
                            ACPS pin should have 4 digits
                          </p>
                        </div>
                      </div>
                      <div className="input_wrp_box">
                        {state.userDetails.roll_no && (
                          <div className="input_wrapper">
                            <label for="roll_no">Roll No</label>
                            <input
                              type="number"
                              min="1"
                              name="roll_no"
                              id="roll_no"
                              value={state.userDetails.roll_no}
                              disabled
                            />
                          </div>
                        )}
                      </div>

                      <div className="btn_wrapper">
                        <button type="submit">Update Changes</button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    {(!isEmpty(state.buyPackageList) &&
                      !isEmpty(state.buyPackageList.current)) ||
                    !isEmpty(state.buyPackageList.past) ? (
                      <div className="your_package_list">
                        <div className="current_package">
                          <h3>Current packages</h3>
                          <div className="crnt_pckgeBx_wrpr">
                            {!isEmpty(state.buyPackageList)
                              ? state.buyPackageList.current.length
                                ? state.buyPackageList.current.map(
                                    (element, index) => {
                                      return (
                                        <>
                                          {/* <div
                                            key={index}
                                            className="crnt_pckge_box"
                                            style={{ marginBottom: "20px" }}
                                          >
                                            <div className="left_title">
                                              <span>
                                                {element.package_name}
                                              </span>
                                            </div>
                                            <div className="right_detail">
                                              {element.is_amirt === 1 ? (
                                                <p
                                                  className={`refund-status ${refundStatusClass(
                                                    element
                                                  )}`}
                                                >
                                                  {element.refund_status}
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                              <h4>{element.coursename}</h4>

                                              <div className="expiry_type_1">
                                                <span>
                                                  Expiry at
                                                  {moment(
                                                    element.txn_expire_date
                                                  ).format(" DD MMM, YYYY")}
                                                </span>

                                                <div
                                                  className={`package_type_1 ${
                                                    element.is_amirt
                                                      ? "refund"
                                                      : ""
                                                  }`}
                                                >
                                                  {parseInt(
                                                    element.is_amirt
                                                  ) === 1 &&
                                                    (element.refund_status
                                                      .length === 0 ? (
                                                      <span
                                                        className="ask-refund"
                                                        onClick={() => {
                                                          clearRefundPackage();
                                                          setCurrentRefundPackage(
                                                            element
                                                          );
                                                          handleOpenRefundModal();
                                                        }}
                                                      >
                                                        Ask Refund
                                                      </span>
                                                    ) : (
                                                      <span className="ask-refund"></span>
                                                    ))}

                                                  <span className="package-status">
                                                    Gold
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div> */}
                                          <div
                                            key={index}
                                            className="crnt_pckge_box"
                                            style={{ marginBottom: "20px" }}
                                          >
                                            <div className="d-flex justify-content-between">
                                              <div className="course-heading">
                                                <h4>{element.package_name}</h4>
                                              </div>
                                              <div className="expiry-date">
                                                <p>Expiring on</p>
                                                <p>
                                                  {moment(
                                                    element.txn_expire_date
                                                  ).format("DD-MM-YYYY")}
                                                </p>
                                              </div>
                                            </div>
                                            <div
                                              style={{ margin: "10px 0 15px" }}
                                            >
                                              {element.coursename}
                                            </div>
                                            <div className="d-flex justify-content-between">
                                              <p>₹{element.txn_amount}</p>
                                              <div>
                                                {parseInt(element.is_amirt) ===
                                                  1 &&
                                                  (element.refund_status
                                                    .length === 0 ? (
                                                    <button className="refund-btn">
                                                      Ask Refund
                                                    </button>
                                                  ) : (
                                                    <></>
                                                  ))}
                                                <button
                                                  className="details-btn"
                                                  onClick={() =>
                                                    history.push("/store")
                                                  }
                                                >
                                                  View Details
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )
                                : "You don’t have any current packages now!"
                              : "You don’t have any current packages now!"}
                          </div>
                        </div>
                        <div className="current_package past_package">
                          <h3>Past Packages</h3>
                          <div className="crnt_pckgeBx_wrpr">
                            {!isEmpty(state.buyPackageList)
                              ? state.buyPackageList.past.length
                                ? state.buyPackageList.past.map(
                                    (element, index) => {
                                      return (
                                        <>
                                          {/* <div
                                            key={index}
                                            className="crnt_pckge_box"
                                            style={{ marginBottom: "20px" }}
                                          >
                                            <div className="left_title">
                                              <span>
                                                {element.package_name}
                                              </span>
                                            </div>
                                            <div className="right_detail">
                                              <h4>{element.coursename}</h4>
                                              <div className="expiry_type">
                                                <span>
                                                  <span>
                                                    Expiry at
                                                    {moment(
                                                      element.txn_expire_date
                                                    ).format(" DD MMM, YYYY")}
                                                  </span>
                                                </span>
                                                <div className="package_type">
                                                  Gold
                                                </div>
                                              </div>
                                            </div>
                                          </div> */}
                                          <div
                                            key={index}
                                            className="crnt_pckge_box"
                                            style={{ marginBottom: "20px" }}
                                          >
                                            <div className="d-flex justify-content-between">
                                              <div className="course-heading">
                                                <h4>{element.package_name}</h4>
                                              </div>
                                              <div className="expiry-date">
                                                <p>Expiring on</p>
                                                <p>
                                                  {moment(
                                                    element.txn_expire_date
                                                  ).format("DD-MM-YYYY")}
                                                </p>
                                              </div>
                                            </div>
                                            <div>{element.coursename}</div>
                                            <div className="d-flex justify-content-between">
                                              <p>₹{element.txn_amount}</p>
                                              <div>
                                                <button className="details-btn">
                                                  View Details
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )
                                : "You don’t have any past packages"
                              : "You don’t have any past packages"}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="no_dataFind">
                        <div className="img_box">
                          <img src={images.m2_store} alt="home" />
                        </div>
                        <h5>No Packages yet!</h5>
                        <p>
                          You haven't purchased any course packages yet! please
                          go and purchase a right package for you
                        </p>
                        <button type="button">Go Store</button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {state.isRefundModalOpen && refunduseMemo}

      {state.isRefundSuccessModalOpen && (
        <RefundSuccessModal
          closeRefundSuccessModal={handleCloseRefundSuccessModal}
          isRefundSuccessModalOpen={state.isRefundSuccessModalOpen}
          startLoading={startLoading}
          finishLoading={finishLoading}
          token={token}
          tokenType={tokenType}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  console.log("state", state);
  return {
    token: state.auth.authToken,
    tokenType: state.auth.authType,
    currentRefundPackage: state.RefundPackageSliceReducer.refundCurrentPackage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setUser: (payload) => {
      dispatch(authActions.setUser(payload));
    },
    setUserPreference: (payload) => {
      dispatch(userPreferenceActions.setUserPreference(payload));
    },

    setCurrentRefundPackage: (payload) => {
      dispatch(setCurrentRefundPackage(payload));
    },

    clearRefundPackage: () => {
      dispatch(clearRefundPackage());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserEditProfileContentComponent);
