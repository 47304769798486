import React from "react";
import TestReportContentComponent from "./testReportContentComponent/TestReportContentComponent";
import { connect } from "react-redux";
import uiActions from "../../../../redux/ui/action";

import {
  setExpectedRank,
  setAllIndiaRank,
  setPlannerTestId,
  setPlannerId,
} from "../../../redux/testDataSlice/TestDataSlice";
const TestReport = (props) => {
  console.log("props",props)
  const {
    loader,
    paperId,
    papers,
    redirectToPredictCollege,
    finishLoading,
    startLoading,
    setExpectedRank,
    setAllIndiaRank,
    parentUserId,
    setPlannerId,
  } = props;
console.log("paperId",paperId)
  return (
    <div>
      <TestReportContentComponent
        redirectToPredictCollege={redirectToPredictCollege}
        loader={loader}
        paperId={paperId}
        papers={papers}
        parentUserId={parentUserId}
        startLoading={startLoading}
        finishLoading={finishLoading}
        setExpectedRank={setExpectedRank}
        setAllIndiaRank={setAllIndiaRank}
        setPlannerId={setPlannerId}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    parentUserId: state.parentrootReducer.parentAuthReducer.userId,
    paperId: state.parentrootReducer.parentTestReducer.paperId,
    papers: state.parentrootReducer.parentTestReducer.papers,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setExpectedRank: (payload) => {
      dispatch(setExpectedRank(payload));
    },
    setAllIndiaRank: (payload) => {
      dispatch(setAllIndiaRank(payload));
    },
    setPlannerTestId: (payload) => {
      dispatch(setPlannerTestId(payload));
    },
    setPlannerId: (payload) => {
      dispatch(setPlannerId(payload));
    },
  };
};

export default connect(mapStateToProps, mapDipatchToProps)(TestReport);
