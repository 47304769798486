import { Fragment, useState } from "react";
import "./doubtsView.scss";
import {
  isEmpty,
  textColorFromBackend,
} from "../../../../../custom_utilities/custom_useful_functions";
import Usercreatedoubt from "../../../user_create_doubt/user_create_doubt";
import ScannedDoubtDetailsView from "../doubtDetailsView/ScannedDoubtDetailsView";
import { backGroundColorFromBackend } from "../../../../../custom_utilities/custom_useful_functions";
import NoDoubt from "./no_doubts/NoDoubt";
import CreateNewDoubtBtn from "./buttons/CreateNewDoubtBtn";
import AnsweredDoubtItem from "./answered_doubts/AnsweredDoubtItem";
import UnAnsweredDoubtItem from "./unanswered_doubts/UnAnsweredDoubtItem";
import ScannedDoubtItem from "./scanned_doubts/ScannedDoubtItem";
import CreateDoubtModal from "./modals/CreateDoubtModal";
import DoubtDetailModal from "./modals/DoubtDetailModal";

const DoubtsView = ({
  subjectCollection,
  answeredDoubts,
  scannedDoubts,
  unAnsweredDoubts,
  subjectDetails,
  handleCreateDoubtBtn,
  handleChangeSubject,
  handleTabChange,
  handleSaveDoubtDetails,
  selectedDetail,
  getQuestionList,
  doubtType,
}) => {
  //bootstarp modal
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(null);
  const handClick = (el) => {
    setSelected(el);
    setShowDbtDetail(true);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showDbtDetail, setShowDbtDetail] = useState(false);
  const handleDtlClose = () => setShowDbtDetail(false);

  const renderSubjectCollection = (subjectCollection) => {
    const newLocal = (
      <div className="doubts_subject_collection_wrapper">
        {!isEmpty(subjectCollection)
          ? subjectCollection.subject.length
            ? subjectCollection.subject.map((element, index) => {
                return (
                  <div>
                    {element.is_show_others === 1 ? (
                      <div>
                        <div
                          key={index}
                          className="doubts_single_select_subject"
                          style={backGroundColorFromBackend(
                            element.sub_id ===
                              parseInt(subjectDetails.subjectId)
                              ? element.color_code
                              : null
                          )}
                        >
                          <img
                            style={{ width: "28px", height: "28px" }}
                            src={element?.icon1}
                            alt="icons"
                          />
                          <button
                            type="button"
                            id={`subject_${element.sub_id}`}
                            className={`subject-button ${
                              element.sub_id ===
                              parseInt(subjectDetails.subjectId)
                                ? "selected"
                                : ""
                            }`}
                            onClick={() =>
                              handleChangeSubject(
                                null,
                                element.sub_id,
                                element.sub_name,
                                element.icon
                              )
                            }
                            style={textColorFromBackend(element.text_color)}
                          >
                            {element.sub_name}
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                );
              })
            : null
          : null}
      </div>
    );
    return <Fragment>{newLocal}</Fragment>;
  };

  const renderQuestion = (question) => {
    return <p dangerouslySetInnerHTML={{ __html: question }}></p>;
  };

  const convertDate = (inputDate) => {
    const date = new Date(inputDate);
    const monthNames = [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "dec",
    ];

    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month}, ${year}`;
  };

  const renderOverallBtn = () => {
    return (
      <div className="overall_create_new_doubt_btn">
        <CreateNewDoubtBtn handleShow={handleShow} />
      </div>
    );
  };

  const renderOverallCreateNewDoubtBtn = () => {
    switch (doubtType) {
      case "answered":
        if (answeredDoubts?.SaveDouteQuestionlist?.data?.length > 0) {
          return renderOverallBtn();
        } else {
          return null;
        }

      case "unanswered":
        if (unAnsweredDoubts?.SaveDouteQuestionlist?.data?.length > 0) {
          return renderOverallBtn();
        } else {
          return null;
        }

      case "scanned":
        if (scannedDoubts?.SaveDouteQuestionlist?.data?.length) {
          return renderOverallBtn();
        } else {
          return null;
        }

      default:
        return null;
    }
  };

  const renderDoubts = () => {
    return (
      <Fragment>
        <div className="doubtsCollectionView_wrapper">
          <div className="doubtsCollectionView_inner_wrapper">
            <div className="section_wrapper">
              <div className="section_inner_wrapper">
                <div className="options_wrapper">
                  <ul
                    className="nav nav-pills custom_nav_pills"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li
                      className="nav-item custom_nav_item"
                      role="presentation"
                    >
                      <button
                        className="nav-link active custom_nav_link"
                        id="pills-answered-doubts-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-answered-doubts"
                        type="button"
                        role="tab"
                        aria-controls="pills-answered-doubts"
                        aria-selected="true"
                        onClick={() => handleTabChange(1, "answered")}
                      >
                        Answered
                      </button>
                    </li>
                    <li
                      className="nav-item custom_nav_item"
                      role="presentation"
                    >
                      <button
                        className="nav-link custom_nav_link"
                        id="pills-unanswered-doubts-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-unanswered-doubts"
                        type="button"
                        role="tab"
                        aria-controls="pills-unanswered-doubts"
                        aria-selected="false"
                        onClick={() => handleTabChange(0, "unanswered")}
                      >
                        Unanswered
                      </button>
                    </li>
                    <li
                      className="nav-item custom_nav_item"
                      role="presentation"
                    >
                      <button
                        className="nav-link custom_nav_link"
                        id="pills-scanned-doubts-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-scanned-doubts"
                        type="button"
                        role="tab"
                        aria-controls="pills-scanned-doubts"
                        aria-selected="false"
                        onClick={() => handleTabChange(0, "scanned")}
                      >
                        Scanned
                      </button>
                    </li>
                  </ul>

                  {renderOverallCreateNewDoubtBtn()}
                </div>
                <div className="tab_wrapper">
                  <div
                    className="tab-content custom_tab_content"
                    id="pills-tabContent"
                  >
                    {/* //////////////answered doubts tab//////// */}
                    <div
                      className="tab-pane fade show active custom_tab_pane"
                      id="pills-answered-doubts"
                      role="tabpanel"
                      aria-labelledby="pills-answered-doubts-tab"
                    >
                      <div className="doubts_tab_wrapper">
                        <div className="outer_content_wrapper">
                          <div className="doubts_collection_wrapper">
                            {!isEmpty(answeredDoubts) ? (
                              answeredDoubts.SaveDouteQuestionlist.data
                                .length ? (
                                answeredDoubts.SaveDouteQuestionlist.data.map(
                                  (element, index) => {
                                    return (
                                      <AnsweredDoubtItem
                                        element={element}
                                        index={index}
                                        handClick={handClick}
                                        convertDate={convertDate}
                                        renderQuestion={renderQuestion}
                                      />
                                    );
                                  }
                                )
                              ) : (
                                <>
                                  <NoDoubt
                                    handleShow={handleShow}
                                    message="You haven't uploaded any doubts yet."
                                  />
                                </>
                              )
                            ) : (
                              <>
                                <NoDoubt
                                  handleShow={handleShow}
                                  message="You haven't uploaded any doubts yet."
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* //////////////unanswered doubts tab//////// */}
                    <div
                      className="tab-pane fade custom_tab_pane"
                      id="pills-unanswered-doubts"
                      role="tabpanel"
                      aria-labelledby="pills-unanswered-doubts-tab"
                    >
                      <div className="doubts_tab_wrapper">
                        <div className="outer_content_wrapper">
                          <div className="doubts_collection_wrapper">
                            {!isEmpty(unAnsweredDoubts) ? (
                              unAnsweredDoubts.SaveDouteQuestionlist.data
                                .length ? (
                                unAnsweredDoubts.SaveDouteQuestionlist.data.map(
                                  (element, index) => {
                                    return (
                                      <UnAnsweredDoubtItem
                                        element={element}
                                        index={index}
                                        handClick={handClick}
                                        convertDate={convertDate}
                                        renderQuestion={renderQuestion}
                                      />
                                    );
                                  }
                                )
                              ) : (
                                <NoDoubt
                                  handleShow={handleShow}
                                  message="You have not got an answer on your Doubts.."
                                />
                              )
                            ) : (
                              <NoDoubt
                                handleShow={handleShow}
                                message="You have not got an answer on your Doubts.."
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* ///////////////scanned doubts tab */}
                    <div
                      className="tab-pane fade custom_tab_pane"
                      id="pills-scanned-doubts"
                      role="tabpanel"
                      aria-labelledby="pills-scanned-doubts-tab"
                    >
                      <div className="doubts_tab_wrapper">
                        <div className="outer_content_wrapper">
                          <div className="doubts_collection_wrapper">
                            {!isEmpty(scannedDoubts) ? (
                              scannedDoubts.SaveDouteQuestionlist.data
                                .length ? (
                                scannedDoubts.SaveDouteQuestionlist.data.map(
                                  (element, index) => {
                                    return (
                                      <ScannedDoubtItem
                                        index={index}
                                        element={element}
                                        handClick={handClick}
                                        convertDate={convertDate}
                                        renderQuestion={renderQuestion}
                                      />
                                    );
                                  }
                                )
                              ) : (
                                <NoDoubt
                                  handleShow={handleShow}
                                  message="You have not got an answer on your Doubts.."
                                />
                              )
                            ) : (
                              <NoDoubt
                                handleShow={handleShow}
                                message="You have not got an answer on your Doubts.."
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DoubtDetailModal
          showDbtDetail={showDbtDetail}
          handleDtlClose={handleDtlClose}
          doubtType={doubtType}
          selectedDoubt={selected}
          renderQuestion={renderQuestion}
          convertDate={convertDate}
        />
      </Fragment>
    );
  };
  return (
    <Fragment>
      <div className="doubtsView_wrapper">
        <div className="doubtsView_inner_wrapper">
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="content_wrapper">
                <div className="left_side_content_wrapper">
                  {renderSubjectCollection(subjectCollection)}
                </div>
                <div className="right_side_content_wrapper">
                  {renderDoubts()}
                  <div className="sec_1_wrapper">
                    <CreateDoubtModal
                      show={show}
                      handleClose={handleClose}
                      Usercreatedoubt={Usercreatedoubt}
                      getQuestionList={getQuestionList}
                      subjectDetails={subjectDetails}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DoubtsView;
