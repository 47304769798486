import React, { useState } from "react";
import "./QuestionDetailPanel.scss";
import ProgressBar from "react-bootstrap/ProgressBar";
import images from "../../../../../../../utilities/images/images";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import QuestionInfoSection from "./questionInfoSection/QuestionInfoSection";
import QuestionOptions from "./questionOptions/QuestionOptions";
import QuestionSwitcher from "./questionSwitcher/QuestionSwitcher";
import QuestionSolution from "./questionSolution/QuestionSolution";

const QuestionDetailPanel = ({
  testQuestionsDetails,
  currentQuestion,
  currentSolutionDetails,
  scrollUp2,
  scrollUp,
  handlePreviousQuestionBtn,
  handleNextQuestionBtn,
}) => {
  const history = useHistory();

  const [lang, setLang] = useState(true);

  let progressCount =
    (100 / testQuestionsDetails?.data?.length) * (currentQuestion + 1);

  const handleGoBack = () => {
    history.push({
      pathname: "/student-tests",
      state: { step: 2 },
    });
  };

  const handleRedirect = () => {
    history.push({
      pathname: "/student-report-pdf",
    });
  };

  let ConvertStringToHTML = function (str) {
    if (!str) {
      return false;
    }
    let parser = new DOMParser();
    let doc = parser.parseFromString(str, "text/html");

    return doc.body.children.length > 0;
  };

  return (
    <div className="QuestionDetailPanel">
      <div className="testsHeader">
        <div className="left">
          <div className="backBtn" onClick={handleGoBack}>
            <img src={images.m2_backBtn} alt="backBtn" />
          </div>

          <div className="section-heading">RBTS Full Test</div>
        </div>
        <div className="right">
          <div className="date">12-Dec-2022</div>
          {/* <div className="obj-type">Obj-Type</div> */}
          <div className="downloadReportBtn">
            <button>
              <span className="text" onClick={handleRedirect}>
                Download report
              </span>
              <span>
                <div className="img">
                  <img src={images.user_activity_48} alt="img" />
                </div>
              </span>
            </button>
          </div>
        </div>
      </div>

      <div className="section_wrapper">
        <div className="testsProgressIndicator">
          <h4>Test Question progress Indicator</h4>
          <div className="ProgressBar_Line">
            <ProgressBar now={progressCount} className="progressBar" />
            <span style={{ left: progressCount - 1 + "%" }}>{`${
              currentQuestion + 1
            }`}</span>
          </div>
          <div className="questionPercentage">
            <span>1</span>
            <span>{testQuestionsDetails?.data?.length}</span>
          </div>
        </div>

        <QuestionInfoSection
          currentSolutionDetails={currentSolutionDetails}
          currentQuestion={currentQuestion}
          lang={lang}
          setLang={setLang}
          ConvertStringToHTML={ConvertStringToHTML}
        />

        <QuestionOptions
          currentSolutionDetails={currentSolutionDetails}
          currentQuestion={currentQuestion}
          lang={lang}
          testQuestionsDetails={testQuestionsDetails}
        />

        <QuestionSwitcher
          currentSolutionDetails={currentSolutionDetails}
          currentQuestion={currentQuestion}
          scrollUp2={scrollUp2}
          scrollUp={scrollUp}
          handlePreviousQuestionBtn={handlePreviousQuestionBtn}
          handleNextQuestionBtn={handleNextQuestionBtn}
          testQuestionsDetails={testQuestionsDetails}
        />

        <QuestionSolution
          currentSolutionDetails={currentSolutionDetails}
          lang={lang}
          ConvertStringToHTML={ConvertStringToHTML}
        />
      </div>
    </div>
  );
};

export default QuestionDetailPanel;
