import { Fragment, useEffect, useState } from "react";
import "./test_questions_view.scss";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Button, Modal } from "react-bootstrap";
import useMathjax from "../../../../../../../hooks/useMathjax";
import images from "../.././../../../../../utilities/images/images";
import axios from "axios";
import {
  url_101,
  url_102,
} from "../../../../../../../custom_utilities/api_services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastifyMessage } from "../../../../../../../custom_utilities/toastify_config";
import ShakaSolutionViewVideoPlayer from "../../../../../../../custom_utilities/shakaVideoPlayer/ShakaSolutionViewVideoPlayer";

const TestQuestionsView = (props) => {
  let {
    testQuestionsDetails,
    currentQuestion,
    countDownTimer,
    handlePreviousQuestionBtn,
    handleNextQuestionBtn,
    handleSaveQuestion,
    handleSingleSelectAnswer,
    handleMultiSelectAnswer,
    handleInputFieldChange,
    handleQuestionJump,
    handleSubmitTest,
    checkQuestion,
    userId,
    startLoading,
    finishLoading,
  } = props;

  const [reportErrorData, setReportErrorData] = useState([]);

  const [saveReportErrorData, setSaveReportErrorData] = useState({
    user_id: userId,
    q_id: testQuestionsDetails[currentQuestion].qid,
    option_id: "",
    description: "",
  });
  //  condition for Report Error modal submit button

  const isDisable =
    saveReportErrorData.option_id.length == 0 ||
    saveReportErrorData.description.length == 0
      ? true
      : false;

  const handleGetReportErrorOptions = () => {
    startLoading();

    try {
      axios.get(url_101).then((response) => {
        setReportErrorData([...response.data.data]);

        if (response.status === 200) {
          finishLoading();
          handleShowReportError();
        }
      });
    } catch (error) {
      finishLoading();
    }
  };

  //function for posting user error data to api

  const handlePostReportErrorData = () => {
    startLoading();
    try {
      axios.post(url_102, saveReportErrorData).then((response) => {
        if (response.data.status === 200) {
          finishLoading();
          handleCloseReportError();
          toastifyMessage({
            status: response.data.status,
            message: "Your Report has Submitted Successfully",
          });
          setSaveReportErrorData({ option_id: "" });
        } else {
          finishLoading();
          handleCloseReportError();
          toastifyMessage({
            status: response.data.status,
            message: "Your Report has Failed",
          });
        }
      });
    } catch (error) {}
  };

  const scrollUp = () => {
    var element = document.getElementsByClassName(
      "questions_serial_number_collection"
    )[0];
    element.scrollTo({
      top: `${(currentQuestion + 1) * 80}`,
      behavior: "smooth",
    });
  };
  const scrollUp2 = () => {
    var element = document.getElementsByClassName(
      "questions_serial_number_collection"
    )[0];
    element.scrollTo({
      top: `${(currentQuestion + 1) * 80 - 160}`,
      behavior: "smooth",
    });
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [videoShow, setVideoShow] = useState(false);

  const [showError, setShowError] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [solText, setSolText] = useState(true);
  const handleCloseReportError = () => {
    setShowError(false);
    setSaveReportErrorData({ option_id: "" });
  };
  const handleShowReportError = () => setShowError(true);

  // for report error modal
  const [lang, setLang] = useState(true);
  const [sol, setSol] = useState(true);
  const [disabled1, setDisabled] = useState(false);
  const [isList, setisList] = useState(true);

  useEffect(() => {
    if (
      countDownTimer.hours == 0 &&
      countDownTimer.minutes == 0 &&
      countDownTimer.seconds == 0
    ) {
      alert("Time is up. Test will be submitted.");
      handleSubmitTest(currentQuestion);
      // handleLastQuestionSave();
    }
  }, [countDownTimer.seconds]);

  useMathjax(isList);
  useMathjax(currentQuestion, lang, sol);

  const handleSol = () => {
    setSol(true);
    setDisabled(false);
  };
  const handleSolution = () => {
    setSol(false);
    //classEditor();
  };
  const disableSol = () => {
    setDisabled(true);
  };

  useEffect(() => {
    const handlePopState = () => {
      handleShow();
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const renderOptions = () => {
    if (testQuestionsDetails.length) {
      let questionsArr = testQuestionsDetails;
      return questionsArr.map((val, i) => {
        let step, type;
        type = val.answer_type;
        if (type.toLowerCase() === "single correct") {
          step = 1;
        } else if (type.toLowerCase() === "integer correct") {
          step = 2;
        } else {
          step = 3;
        }
        switch (step) {
          case 1:
            return renderSingleSelect(questionsArr[i], i, lang);
          case 2:
            return renderInputField(i);
          case 3:
            return renderMultiSelect(questionsArr[i], i);
          default:
            return null;
        }
      });
    } else {
      return null;
    }
  };
  const classEditor = (s, wc, optionId) => {
    if (!sol && Number(s)) {
      return "single_option_wrapper classCorrect";
    } else if (!sol && wc && wc.indexOf(Number(optionId)) > -1) {
      return "single_option_wrapper classWrong";
    } else {
      return "single_option_wrapper";
    }
  };

  const handlePurticularQuestion = () => {
    if (
      testQuestionsDetails[currentQuestion - 1].userAnswerCollection.length ||
      testQuestionsDetails[currentQuestion - 1].isAttempted === true
    ) {
      handleSolution();
    } else {
      handleSol();
    }
  };

  const handlePurticularQuestion3 = () => {
    if (
      testQuestionsDetails[currentQuestion + 1].userAnswerCollection.length ||
      testQuestionsDetails[currentQuestion + 1].isAttempted === true
    ) {
      handleSolution();
    } else {
      handleSol();
    }
  };

  const handlePurticularQuestion2 = (currentQuestion) => {
    if (testQuestionsDetails[currentQuestion].userAnswerCollection.length) {
      handleSolution();
    } else {
      handleSol();
    }
  };
  const renderSingleSelect = (questionData, currentQuestion2) => {
    return (
      <Fragment>
        <div
          className={
            currentQuestion2 == currentQuestion
              ? "single_select_option_collection_wrapper show"
              : "single_select_option_collection_wrapper hide"
          }
        >
          {lang
            ? questionData.option.length
              ? questionData.option.map((element, index) => {
                  return (
                    <div key={index} className="single_select">
                      <input
                        type="checkbox"
                        id={element.option_id}
                        name={`current_question_${currentQuestion2}`}
                        disabled={sol ? false : true}
                        checked={
                          testQuestionsDetails[currentQuestion2]
                            .userAnswerCollection.length
                            ? testQuestionsDetails[currentQuestion2]
                                .userAnswerCollection[0].option_id ===
                              element.option_id
                            : element.is_user_correct_ans == 1
                            ? true
                            : false
                        }
                        // checked={element.is_user_correct_ans == 1}
                        onChange={(event) => {
                          handleSingleSelectAnswer(event, element.option_id);
                          disableSol();
                        }}
                      />
                      <label
                        htmlFor={element.option_id}
                        className={classEditor(
                          element.is_correct,
                          testQuestionsDetails[currentQuestion2]
                            .wrongAnswerIdCollection,
                          element.option_id
                        )}
                      >
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className="text_content_3"
                            dangerouslySetInnerHTML={renderOptionText(
                              element.option
                            )}
                          ></p>
                        </div>
                      </label>
                    </div>
                  );
                })
              : null
            : questionData.option_hindi.length
            ? questionData.option_hindi.map((element, index) => {
                return (
                  <div key={index} className="single_select">
                    <input
                      type="checkbox"
                      id={element.option_id}
                      name={`current_question_${currentQuestion2}`}
                      disabled={sol ? false : true}
                      checked={
                        testQuestionsDetails[currentQuestion2]
                          .userAnswerCollection.length
                          ? testQuestionsDetails[currentQuestion2]
                              .userAnswerCollection[0].option_id ===
                            element.option_id
                          : element.is_user_correct_ans == 1
                          ? true
                          : false
                      }
                      // checked={element.is_user_correct_ans == 1}
                      onChange={(event) => {
                        handleSingleSelectAnswer(event, element.option_id);
                        disableSol();
                      }}
                    />
                    <label
                      htmlFor={element.option_id}
                      className={classEditor(
                        element.is_correct,
                        testQuestionsDetails[currentQuestion2]
                          .wrongAnswerIdCollection,
                        element.option_id
                      )}
                    >
                      <div className="option_initial">
                        <p className="text_content_2">{`${renderOptionInitial(
                          index
                        )}.`}</p>
                      </div>
                      <div className="option_final">
                        <p
                          className="text_content_3"
                          dangerouslySetInnerHTML={renderOptionText(
                            element.option
                          )}
                        ></p>
                      </div>
                    </label>
                  </div>
                );
              })
            : null}
        </div>
      </Fragment>
    );
  };

  const renderMultiSelect = (questionData, currentQuestion2) => {
    return (
      <Fragment>
        <div
          className={
            currentQuestion2 == currentQuestion
              ? "multi_select_option_collection_wrapper show"
              : "multi_select_option_collection_wrapper hide"
          }
        >
          {questionData.option.length
            ? questionData.option.map((element, index) => {
                return (
                  <div key={index} className="single_select">
                    <input
                      type="checkbox"
                      id={element.option_id}
                      name={`current_question_${currentQuestion2}`}
                      disabled={sol ? false : true}
                      checked={
                        testQuestionsDetails[currentQuestion2]
                          .userAnswerCollection.length
                          ? testQuestionsDetails[
                              currentQuestion2
                            ].userAnswerCollection.find((item) => {
                              return item.option_id === element.option_id;
                            })
                          : false
                      }
                      onChange={(event) => {
                        handleMultiSelectAnswer(event, element.option_id);
                        disableSol();
                      }}
                    />
                    <label
                      htmlFor={element.option_id}
                      className={classEditor(
                        element.is_correct,
                        testQuestionsDetails[currentQuestion2]
                          .wrongAnswerIdCollection,
                        element.option_id
                      )}
                    >
                      <div className="option_initial">
                        <p className="text_content_2">{`${renderOptionInitial(
                          index
                        )}.`}</p>
                      </div>
                      <div className="option_final">
                        <p
                          className="text_content_3"
                          dangerouslySetInnerHTML={renderOptionText(
                            element.option
                          )}
                        ></p>
                      </div>
                    </label>
                  </div>
                );
              })
            : null}
        </div>
      </Fragment>
    );
  };
  const renderInputField = (currentQuestion2) => {
    return (
      <Fragment>
        <div
          className={
            currentQuestion2 == currentQuestion
              ? "input_wrapper show2"
              : "input_wrapper hide"
          }
        >
          <div className="input_inner_wrapper">
            <input
              key={currentQuestion2}
              type="number"
              placeholder="Enter your answer"
              value={testQuestionsDetails[currentQuestion2].integer_answer}
              onChange={(event) => {
                handleInputFieldChange(event);
                disableSol();
              }}
              disabled={checkQuestion ? false : true}
            />
          </div>
        </div>
      </Fragment>
    );
  };

  const renderQuestionText = () => {
    if (testQuestionsDetails.length) {
      let questionsArr = testQuestionsDetails;
      return {
        __html: lang
          ? questionsArr[currentQuestion].q_text
          : questionsArr[currentQuestion].q_text_hindi !== null
          ? questionsArr[currentQuestion].q_text_hindi
          : questionsArr[currentQuestion].q_text,
      };
    } else {
      return null;
    }
  };

  const renderOptionInitial = (index) => {
    let alphabetArr = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    return alphabetArr[index];
  };

  const renderOptionText = (optionText) => {
    return { __html: optionText };
  };

  const renderQuestionNumber = (index) => {
    if (index <= 8) {
      return `${index + 1}.`;
    } else {
      return `${index + 1}.`;
    }
  };

  const renderQuestionLabel1 = (question, index) => {
    if (currentQuestion === index) {
      return (
        <label
          className="unanswered_lbl unanswered_lbl1 current_lbl"
          onClick={() => handleQuestionJump(index)}
        >
          <div className="questionBox questionBox1">
            <div>{`${index + 1}.`}</div>
            <div className="QBox">
              <p
                dangerouslySetInnerHTML={{
                  __html: question.q_text,
                }}
              ></p>
            </div>
          </div>
          <img className="arrow" src={images.right_Ar} />
        </label>
      );
    } else {
      if (question.isAttempted) {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 review_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                <div>{`${index + 1}.`}</div>
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else if (question.isAnswerGuessed) {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 isGussedAnswer"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                <div>{`${index + 1}.`}</div>
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 answered_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                <div>{`${index + 1}.`}</div>
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        }
      } else if (question.isAnswerGuessed) {
        return (
          <label
            className="unanswered_lbl unanswered_lbl1 isGussedAnswer"
            onClick={() => handleQuestionJump(index)}
          >
            <div className="questionBox questionBox1">
              <div>{`${index + 1}.`}</div>
              <div className="QBox">
                <p
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div>
            </div>
            <img className="arrow" src={images.right_Ar} />
          </label>
        );
      } else {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 review_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                <div>{`${index + 1}.`}</div>
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else if (question.isSkipped) {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 skip_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                <div>{`${index + 1}.`}</div>
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else if (
          question.option?.filter((m) => m.is_user_correct_ans == 1).length ||
          question.integer_answer?.length
        ) {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1 answered_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                <div>{`${index + 1}.`}</div>
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl unanswered_lbl1"
              onClick={() => handleQuestionJump(index)}
            >
              <div className="questionBox questionBox1">
                <div>{`${index + 1}.`}</div>
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        }
      }
    }
  };

  const renderQuestionLabel = (question, index) => {
    if (currentQuestion === index) {
      return (
        <label
          className="unanswered_lbl current_lbl"
          onClick={() => {
            handleQuestionJump(index);
            handlePurticularQuestion2(index);
            setVideoShow(false);
            setToggleMenu(false);
          }}
        >
          <div className="questionBox">
            {`${index + 1}`}
            {/* <div className="QBox">
              <p
                dangerouslySetInnerHTML={{
                  __html: lang
                    ? question.q_text
                    : question.q_text_hindi !== null
                    ? question.q_text_hindi
                    : question.q_text,
                }}
              ></p>
            </div> */}
          </div>
          {/* <img className="arrow" src={images.right_Ar} /> */}
        </label>
      );
    } else {
      if (question.isAttempted) {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl review_lbl"
              onClick={
                (() => handleQuestionJump(index),
                setVideoShow(false),
                setToggleMenu(false))
              }
            >
              <div className="questionBox">
                <div className="questionBox">
                  {`${index + 1}`}
                  {/* <div className="QBox">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: question.q_text,
                      }}
                    ></p>
                  </div> */}
                </div>
                {/* <img className="arrow" src={images.right_Ar} /> */}
              </div>
            </label>
          );
        } else if (question.isAnswerGuessed) {
          return (
            <label
              className="unanswered_lbl isGussedAnswer"
              onClick={
                (() => handleQuestionJump(index),
                setVideoShow(false),
                setToggleMenu(false))
              }
            >
              <div className="questionBox">
                {`${index + 1}`}
                {/* <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div> */}
              </div>
              {/* <img className="arrow" src={images.right_Ar} /> */}
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl answered_lbl"
              onClick={() => {
                handleQuestionJump(index);
                handlePurticularQuestion2(index);
                setVideoShow(false);
                setToggleMenu(false);
              }}
            >
              <div className="questionBox">
                {`${index + 1}`}
                {/* <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div> */}
              </div>
              {/* <img className="arrow" src={images.right_Ar} /> */}
            </label>
          );
        }
      } else if (question.isAnswerGuessed) {
        return (
          <label
            className="unanswered_lbl isGussedAnswer"
            onClick={
              (() => handleQuestionJump(index),
              setVideoShow(false),
              setToggleMenu(false))
            }
          >
            <div className="questionBox">
              {`${index + 1}`}
              {/* <div className="QBox">
                <p
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div> */}
            </div>
            {/* <img className="arrow" src={images.right_Ar} /> */}
          </label>
        );
      } else {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl review_lbl"
              onClick={
                (() => handleQuestionJump(index),
                setVideoShow(false),
                setToggleMenu(false))
              }
            >
              <div className="questionBox">
                {`${index + 1}`}
                {/* <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div> */}
              </div>
              {/* <img className="arrow" src={images.right_Ar} /> */}
            </label>
          );
        } else if (question.isSkipped) {
          return (
            <label
              className="unanswered_lbl skip_lbl"
              onClick={
                (() => handleQuestionJump(index),
                setVideoShow(false),
                setToggleMenu(false))
              }
            >
              <div className="questionBox">
                {`${index + 1}`}
                {/* <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div> */}
              </div>
              {/* <img className="arrow" src={images.right_Ar} /> */}
            </label>
          );
        } else if (
          question.option?.filter((m) => m.is_user_correct_ans == 1).length ||
          question.integer_answer?.length
        ) {
          return (
            <label
              className="unanswered_lbl answered_lbl"
              onClick={
                (() => handleQuestionJump(index),
                setVideoShow(false),
                setToggleMenu(false))
              }
            >
              <div className="questionBox">
                {`${index + 1}`}
                {/* <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div> */}
              </div>
              {/* <img className="arrow" src={images.right_Ar} /> */}
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl"
              onClick={() => {
                handleQuestionJump(index);
                handlePurticularQuestion2(index);
                setVideoShow(false);
                setToggleMenu(false);
              }}
            >
              <div className="questionBox">
                {`${index + 1}`}
                {/* <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div> */}
              </div>
              {/* <img className="arrow" src={images.right_Ar} /> */}
            </label>
          );
        }
      }
    }
  };

  let ConvertStringToHTML = function (str) {
    if (!str) {
      return false;
    }
    let parser = new DOMParser();
    let doc = parser.parseFromString(str, "text/html");

    return doc.body.children.length > 0;
  };

  const handleQuestionType = () => {
    if (testQuestionsDetails.length) {
      let questionsArr = testQuestionsDetails;
      return questionsArr[currentQuestion].answer_type;
    } else {
      return null;
    }
  };

  const handleSubTopicText = () => {
    if (testQuestionsDetails.length) {
      let questionsArr = testQuestionsDetails;

      if (questionsArr[currentQuestion].hasOwnProperty("sub_topic")) {
        return questionsArr[currentQuestion].sub_topic;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  let progressCount =
    (100 / testQuestionsDetails.length) * (currentQuestion + 1);
  // const config = {
  //   //loader: { load: ["[tex]/html"] },
  //   loader: {
  //     load: [
  //       "input/asciimath",
  //       "output/chtml",
  //       "ui/menu",
  //       "[mml]/mml3",
  //       "[tex]/html",
  //     ],
  //   },
  // };

  const config = {
    "fast-preview": {
      disabled: true,
    },
    tex2jax: {
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"],
      ],
      displayMath: [
        ["$$", "$$"],
        ["\\[", "\\]"],
      ],
    },
    messageStyle: "none",
  };

  const handleOnLoad = () => {
    let frame = document.querySelector("#iframe");
    frame.contentWindow.postMessage(
      `{"activate": true,"userId":"${userId}"}`,
      "*"
    );

    // //////jump to particular time///////////////////////
    // frame.contentWindow.postMessage(JSON.stringify({ seekTo: 1 }), "*");

    ////////////////testing///////////////////////////////////
    let eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    let eventer = window[eventMethod];
    let messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

    eventer(messageEvent, function (e) {
      if (e.data && e.data.currentTime) {
        let videoTimmingDetails = { currentTime: e.data.currentTime };
        sessionStorage.setItem(
          "videoTimmingDetails",
          JSON.stringify(videoTimmingDetails)
        );
      }
    });
  };

  const handleVideoShow = () => {
    setVideoShow(!videoShow);
    const targetDiv = document.querySelector(".sub_section_wrapper");
    setTimeout(() => {
      targetDiv.scrollTop = targetDiv.scrollHeight;
    }, 1);
  };

  const renderSingleQuestion = (element) => {
    if (lang) {
      return (
        <p
          dangerouslySetInnerHTML={{
            __html: element.q_text,
          }}
        ></p>
      );
    } else {
      if (ConvertStringToHTML(element?.q_text_hindi)) {
        return (
          <p
            dangerouslySetInnerHTML={{
              __html: element.q_text_hindi,
            }}
          ></p>
        );
      } else {
        return (
          <p
            dangerouslySetInnerHTML={{
              __html: element.q_text,
            }}
          ></p>
        );
      }
    }
  };

  const renderVideo = (element) => {
    const videoId = element.video_id_english;
    const hindiVideoId = element.video_id_hindi;
    const englishVideoCriptId = element.english_video_cript_id;
    const hindiVideoCriptId = element.hindi_video_cript_id;

    const isHindi = !lang && hindiVideoId && hindiVideoCriptId;
    const selectedVideoId = isHindi ? hindiVideoId : videoId;
    const selectedVideoCriptId = isHindi
      ? hindiVideoCriptId
      : englishVideoCriptId;

    const urlPattern = /^https:\/\/speed\.mogiio\.com\/embed3\/[0-9a-f]{24}$/;

    if (!urlPattern.test(selectedVideoId)) {
      return null;
    }

    // const buttonLabel = lang ? "View Video Solution" : "वीडियो समाधान देखें";
    const buttonLabel =
      videoShow && !lang
        ? "वीडियो समाधान बंद करें" // Close Video Solution
        : videoShow && lang
        ? "Close Video Solution"
        : !videoShow && !lang
        ? "वीडियो समाधान देखें" // View Video Solution
        : "View Video Solution";

    if (selectedVideoCriptId && selectedVideoCriptId.trim() !== "") {
      return (
        <div className="videoWrapper">
          <button className="view_videoSolution_btn" onClick={handleVideoShow}>
            {buttonLabel}
          </button>
          {videoShow ? (
            <ShakaSolutionViewVideoPlayer
              id_video={selectedVideoCriptId}
              className="shakaPlayer"
            />
          ) : null}
        </div>
      );
    } else {
      return (
        <div className="videoWrapper">
          {selectedVideoId && (
            <button
              className="view_videoSolution_btn"
              onClick={handleVideoShow}
            >
              {buttonLabel}
            </button>
          )}

          {videoShow ? (
            <iframe
              id="iframe"
              src={selectedVideoId}
              onLoad={handleOnLoad}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : null}
        </div>
      );
    }
  };

  return (
    <Fragment>
      <div className="cps_test_questions_view_wrapper_cps">
        <div className="test_questions_view_inner_wrapper">
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="section_content_wrapper">
                {toggleMenu ? (
                  <div
                    className="section_content_wrapper_backdrop"
                    onClick={() => {
                      setToggleMenu(false);
                    }}
                  ></div>
                ) : (
                  ""
                )}
                <div
                  className={`left_side_content_wrapper ${
                    toggleMenu ? "left_side_content_wrapperToggle" : ""
                  }`}
                >
                  <div className="sub_section_1_wrapper">
                    <div>
                      <button
                        type="button"
                        class="btn-close closebtn"
                        aria-label="Close"
                        onClick={() => {
                          setToggleMenu(false);
                        }}
                      ></button>

                      <div className="text_content_wrapper">
                        <p className="text_content">
                          {testQuestionsDetails[currentQuestion]?.exam_name ??
                            "Test"}
                        </p>
                        <button onClick={() => handleShow()}>Submit</button>
                      </div>
                      <div className="sub_section_2_wrapper">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="m-0">All Questions</p>
                          <div className="d-flex gap-2">
                            <button onClick={() => setisList(true)}>
                              {isList ? (
                                <img
                                  src={images.user_activity_list1}
                                  alt="list1"
                                />
                              ) : (
                                <img
                                  src={images.user_activity_list}
                                  alt="list"
                                />
                              )}
                            </button>
                            <button onClick={() => setisList(false)}>
                              {isList ? (
                                <img
                                  src={images.user_activity_grid}
                                  alt="grid"
                                />
                              ) : (
                                <img
                                  src={images.user_activity_grid1}
                                  alt="grid1"
                                />
                              )}
                            </button>
                          </div>
                        </div>
                        <div className="question_marking_wrapper">
                          <div className="single_marking">
                            <label className="box_pic box_pick_background_1"></label>
                            <label className="question_marking_text">
                              Completed
                            </label>
                          </div>
                          <div className="single_marking">
                            <label className="box_pic box_pick_background_3"></label>
                            <label className="question_marking_text">
                              Pending
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        className="questions_serial_number_collection"
                        // ref={questionsCollectionRef}
                      >
                        {testQuestionsDetails.length
                          ? testQuestionsDetails.map((element, index) => {
                              return (
                                <div
                                  key={index}
                                  className={`single_question_number_wrapper ${
                                    isList
                                      ? "single_question_number_wrapper1"
                                      : ""
                                  }`}
                                >
                                  {isList ? (
                                    <>{renderQuestionLabel1(element, index)}</>
                                  ) : (
                                    <>{renderQuestionLabel(element, index)}</>
                                  )}
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right_side_content_wrapper">
                  <div className="sub_section_wrapper">
                    <div className="timer_container d-flex justify-content-between">
                      <div className="question_counter">
                        <span>{currentQuestion + 1}</span>
                        {` / `}
                        <span>{testQuestionsDetails.length}</span>
                      </div>
                      <div className="timer_div">
                        <span>
                          <label className="time_number">
                            {countDownTimer.hours}
                          </label>
                          <label className="time_partition">:</label>
                          <label className="time_number">
                            {countDownTimer.minutes}
                          </label>
                          <label className="time_partition">:</label>
                          <label className="time_number">
                            {countDownTimer.seconds > 9
                              ? countDownTimer.seconds
                              : `${"0" + countDownTimer.seconds}`}
                          </label>
                        </span>
                      </div>
                      <div className="right_button d-flex">
                        {testQuestionsDetails[currentQuestion] &&
                        testQuestionsDetails[currentQuestion].q_text_hindi ? (
                          <button onClick={() => setLang(!lang)}>
                            {lang ? `अ` : `En`}
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div style={{ overflowY: "auto", padding: "10px" }}>
                      <div className="question_topic_container">
                        <p>
                          Question type:
                          <span>
                            {` `}
                            {testQuestionsDetails[currentQuestion]?.type}
                          </span>
                        </p>
                      </div>
                      <div className="question_details">
                        <p className="question_number">
                          Question {currentQuestion + 1}
                        </p>
                        <div className="question">
                          <div>
                            <div id="your-dynamic-content">
                              {testQuestionsDetails.length
                                ? testQuestionsDetails.map((elem, index) => {
                                    if (index == currentQuestion) {
                                      return <>{renderSingleQuestion(elem)}</>;
                                    }
                                  })
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="sub_sub_section_wrapper_3">
                        {renderOptions()}
                      </div>

                      <ToastContainer />

                      <Modal
                        show={show}
                        onHide={handleClose}
                        className="onSubmitModal"
                        centered
                      >
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                          <div className="submit_Popup">
                            <div className="exit_img">
                              <img src={images.m2_exit1} alt="" />
                            </div>
                            <h5>Are you Sure?</h5>
                            <p>
                              Do you want to continue the exam or you want to
                              Submit.
                            </p>
                            <div className="submitCancelBtn">
                              <button className="cancel" onClick={handleClose}>
                                Cancel
                              </button>
                              <button onClick={() => handleSubmitTest()}>
                                Submit
                              </button>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>

                      <Modal
                        show={showError}
                        onHide={handleCloseReportError}
                        className="errorModal"
                      >
                        <Modal.Header closeButton>
                          {" "}
                          <h3>Report an Error</h3>
                        </Modal.Header>
                        <Modal.Body className="modal-body">
                          <div className="error_Popup">
                            <p className="text_content">
                              You can just write to us, If there is any
                              incorrect or error in question and given options.
                            </p>
                            <ul className="errorList">
                              {reportErrorData?.map((element, index) => {
                                return (
                                  <li className="errorListItem" key={index}>
                                    <input
                                      type="radio"
                                      id={element.id}
                                      name="error"
                                      value={element.option_name}
                                      onChange={(event) => {
                                        setSaveReportErrorData({
                                          ...saveReportErrorData,
                                          option_id: event.target.id,
                                        });
                                      }}
                                    />{" "}
                                    <label htmlFor={element.id}>
                                      {element.option_name}
                                    </label>
                                  </li>
                                );
                              })}
                            </ul>
                            <textarea
                              className="textarea_content"
                              type="text"
                              onChange={(event) =>
                                setSaveReportErrorData({
                                  ...saveReportErrorData,
                                  description: event.target.value,
                                })
                              }
                              placeholder="Write to Us..."
                            />{" "}
                            <br />
                            <Button
                              disabled={isDisable}
                              onClick={handlePostReportErrorData}
                            >
                              Submit
                            </Button>
                          </div>
                        </Modal.Body>
                      </Modal>

                      {!sol ? (
                        <div>
                          <div className="solutionBoxWrapper">
                            <div className="sol_text">Solution :</div>
                            {testQuestionsDetails.length
                              ? testQuestionsDetails.map((element, index) => {
                                  return (
                                    <>
                                      <p
                                        className={
                                          index == currentQuestion && lang
                                            ? "show"
                                            : "hide"
                                        }
                                        key={element.qid + "en"}
                                        dangerouslySetInnerHTML={{
                                          __html: element.sol_text,
                                        }}
                                      ></p>
                                      <p
                                        className={
                                          index == currentQuestion && !lang
                                            ? "show"
                                            : "hide"
                                        }
                                        key={element.qid + "hi"}
                                        dangerouslySetInnerHTML={{
                                          __html: element.sol_text_hindi,
                                        }}
                                      ></p>
                                    </>
                                  );
                                })
                              : null}
                          </div>
                          {true
                            ? testQuestionsDetails.map((elem, index) => {
                                // if (index === currentQuestion) {
                                return <>{renderVideo(elem)}</>;
                                // }
                              })
                            : null}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      margin: "auto 40px 25px",
                      display: "inline-block",
                      color: "#B50503",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "14px",
                    }}
                  >
                    <span style={{ marginRight: "5px", cursor: "pointer" }}>
                      <img src={images.user_activity_14_1} alt="" />
                    </span>{" "}
                    <span
                      style={{ textDecoration: "none", cursor: "pointer" }}
                      onClick={handleGetReportErrorOptions}
                    >
                      Report an error
                    </span>
                  </div>
                  <div className="sub_sub_section_wrapper_5">
                    <div className="left_side_wrapper">
                      {currentQuestion > 0 && (
                        <div className="btn_wrapper">
                          <button
                            onClick={() => {
                              handlePreviousQuestionBtn();
                              scrollUp2();
                              handlePurticularQuestion();
                              setVideoShow(false);
                            }}
                          >
                            Previous
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="right_side_wrapper">
                      {testQuestionsDetails.length === currentQuestion + 1 ? (
                        <div className="btn_wrapper">
                          {sol ? (
                            <button
                              disabled={disabled1 ? false : true}
                              className={!disabled1 ? "opacity" : "true"}
                              onClick={() => handleSolution()}
                            >
                              Solution
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                handleSaveQuestion();
                                handleShow();
                              }}
                            >
                              Submit
                            </button>
                          )}
                        </div>
                      ) : (
                        <div className="btn_wrapper">
                          {sol ? (
                            <button
                              disabled={disabled1 ? false : true}
                              className={!disabled1 ? "opacity" : "true"}
                              onClick={() => handleSolution()}
                            >
                              Solution
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                handleNextQuestionBtn();
                                // handleSol();
                                scrollUp();
                                handlePurticularQuestion3();
                                setVideoShow(false);
                                // setLang(true);
                              }}
                            >
                              Next
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TestQuestionsView;
