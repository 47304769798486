import { React, useEffect, useState } from "react";
import "./AdaptiveCpsSubjects.scss";
import { isEmpty } from "../../../../../../../../custom_utilities/custom_useful_functions";
import { url_83 ,url_213 } from "../../../../../../../../custom_utilities/api_services";
import images from "../../../../../../../../utilities/images/images";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
//Redux
import { connect } from "react-redux";

import uiActions from "../../../../../../../../redux/ui/action";

// import adaptiveCpsActions from "../../../../../../redux/adaptive_cps/action";

import adaptiveCpsActions from "../../../../../../../../redux/adaptive_cps/action";
import { Modal } from "react-bootstrap";

const AdaptiveCpsSubjects = (props) => {
  let {
    userPreference,
    handleSubjectClick,
    handleSubjectClick2,
    is_subscribe,
    startLoading,
    finishLoading,
    CommonBannerData,
  } = props;
  let history = useHistory();
  const [state, setState] = useState({
    topicSubjectList: {},
    showModal: false,
  });

  useEffect(() => {
    getTopicSubjectList();
  }, []);

  //subjectList
  const getTopicSubjectList = async () => {
    let { courseDetails, classDetails } = userPreference;

    let data = {
      course_id: courseDetails[0].course_id,
      class_id: classDetails.class_id,
    };
    startLoading();
    try {
      const response = await axios.get(
        url_213,
        data
      );
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          topicSubjectList: response.data.data,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  let { subject: subjectList } = state.topicSubjectList;

  const handleSubjectClickBoth = (sub_name) => {
    if(false) {
      // history.push({
      //   pathname: "/store", state: { tab: "online_learning" }
      // })
      setState((prevState) => ({
        ...prevState,
        showModal: true,
      }));
    } else {
      if (is_subscribe === true) {
      console.log("subscribe");
      handleSubjectClick2(sub_name);
    } else {
      handleSubjectClick(sub_name);
    }}
  };

  const backGroundColorFromBackend = (color_code) => {
    const colorCode = color_code ? color_code.split(",") : null;
  
    if (colorCode !== null) {
      return {
        background: `linear-gradient(277deg,${colorCode[0]}, ${colorCode[1]} 101.46%)`,
      };
    } else {
      return {
        background: "",
      };
    }
  };
  

  return (
    <>
      <div className="ACpsTopicWiseWrapper justify-content-between">
        {/* <div className="section-heading col-12">Choose a Subject</div> */}
        {!isEmpty(subjectList)
          ? subjectList.length
            ? subjectList.map((elem, index) => {
                return (
                  <div
                    key={index}
                    className="ACpsTopicBox"
                    onClick={() => handleSubjectClickBoth(elem.sub_name)}
                    style={backGroundColorFromBackend(
                      elem.color_code
                    )}
                  >
                    <div className="ACpsTopicNameImg">
                      <div className="ACpsImg_wrp">
                        <img src={elem.icon} alt="icon" />
                      </div>
                      <div className="name">{elem.sub_name}</div>
                    </div>

                    <div className="arrow">
                      <img src={images.right_Ar} alt="arrow" />
                    </div>
                  </div>
                );
              })
            : null
          : null}
      </div>
      <Modal
          id="acpsm"
          show={state.showModal}
          onHide={() =>
            setState((prevState) => ({
              ...prevState,
              showModal: false,
            }))
          }
          animation={false}
          centered
        >
          <Modal.Body>
            <p>Adaptive CPS</p>
            <p>
              <strong style={{ color: "#121212", fontWeight: "500" }}>
                All your free attempts have been completed!
              </strong>
            </p>
            <p>
            Please click on “<strong>Buy Now</strong>” button to purchase Adaptive CPS package for attempting further tests.
            </p>
            <div className="d-flex justify-content-center">
              <button
                onClick={() => {
                  history.push({
                    pathname: "/store",
                    state: { tab: "online_learning" },
                  });
                }}
              >
                Buy Now
              </button>
            </div>
            <div
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  showModal: false,
                }))
              }
            >
              <img src={images.close_btn} alt="close" />
            </div>
          </Modal.Body>
        </Modal>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    userPreference: state.userPreference,
    CommonBannerData: state.userActivity.commonBannerData,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },

    setAdaptiveCpsSubjectAction: (payload) => {
      dispatch(adaptiveCpsActions.setAdaptiveCpsSubject(payload));
    },
  };
};

export default connect(mapStateToProps, mapDipatchToProps)(AdaptiveCpsSubjects);
