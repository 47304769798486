import React, { useState, useEffect } from "react";
import ParentHeader from "../parentHeader/ParentHeader";
import "./Tests.scss";
import TestsContentComponent from "./testsContentComponent/TestsContentComponent";
import TestWiseAnalysis from "./testWiseAnalysis/TestWiseAnalysis";
import { useLocation } from "react-router-dom";
import uiActions from "../../redux/ui/action";
import { connect } from "react-redux";
import {
  setPaperId,
  setPlannerTestId,
} from "../redux/testDataSlice/TestDataSlice";
import HeaderComponent from "../../containers/logged_user_components/structure_components/desktop_screens/header_component/header_component";

const Tests = (props) => {
  let { loader, startLoading, finishLoading, setPaperIds, setPlannerTestIds,parentPaperId, parentUserId, loginFromParent } =
    props;
  let location = useLocation();

  let step = location.state.step;
  let roll_no = location.state.roll_no;

  let [state, setState] = useState({
    step: step,
  });

  const handleStep1 = () => {
    setState((prevState) => ({
      ...prevState,
      step: 1,
    }));
  };

  const handleStep2 = (paperId, planner_test_id) => {
    setPaperIds(paperId)
    setPlannerTestIds(planner_test_id)
    // setPlannerTestId(planner_test_id);
    setState((prevState) => ({
      ...prevState,
      step: 2,
    }));
  };

  return (
    <div className="components">
      {loginFromParent && <ParentHeader />}
      {!loginFromParent && (
        <div className="studentHeader">
          {" "}
          <HeaderComponent />
        </div>
      )}

      <div className="testsComponent">
        <div className="testsContentInrWrapper">
          {state.step === 1 && (
            <TestWiseAnalysis
              handleStep2={handleStep2}
              roll_no={roll_no}
              step={step}
            />
          )}
        </div>
        <div>
          {state.step === 2 && (
            <TestsContentComponent
              loader={loader}
              startLoading={startLoading}
              finishLoading={finishLoading}
              handleStep1={handleStep1}
              paperId={parentPaperId}
              parentUserId={parentUserId}
              loginFromParent={loginFromParent}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log("state loginFromParent", state);
  return {
    loader: state.ui.loader,
    studentPaperId: state.parentrootReducer.parentTestReducer.studentPaperId,
    studentUserId: state.parentrootReducer.parentTestReducer.studentUserId,
    parentUserId: state.parentrootReducer.parentAuthReducer.userId,
    parentPaperId: state.parentrootReducer.parentTestReducer.paperId,
    loginFromParent: state.parentrootReducer.parentAuthReducer.loginFromParent,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },

    setPaperIds: (payload) => {
      dispatch(setPaperId(payload));
    },

    setPlannerTestIds: (payload) => {
      dispatch(setPlannerTestId(payload));
    },
  };
};

export default connect(mapStateToProps, mapDipatchToProps)(Tests);
