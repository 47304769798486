import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AdaptiveCpsCustomPapers.scss";
import { isEmpty } from "../../../../../../../custom_utilities/custom_useful_functions";
import { connect } from "react-redux";
import images from "../../../../../../../utilities/images/images";
import SubjectSelection from "./subjectSelection/SubjectSelection";
import CustomPaperBox from "./customPaperBox/CustomPaperBox";
import DateRangeModal from "./dateRangeModal/DateRangeModal";
import { useHistory } from "react-router-dom";
import adaptiveCpsActions from "../../../../../../../redux/adaptive_cps/action";

const AdaptiveCpsCustomPapers = (props) => {
  const {
    startLoading,
    finishLoading,
    token,
    tokenType,
    userPreference,
    setAdaptiveCpsId,
    setAdaptiveCpsSubjectAction,
  } = props;

  const [state, setState] = useState({
    questionBankTestList: [],
    topicSubjectList: [],
    currentSubject: "",
    open: false,
  });

  const history = useHistory();
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    getSubjectList();
  }, []);

  useEffect(() => {
    if (state.currentSubject && range[0].startDate && range[0].endDate) {
      getAdaptiveCpsTestList();
    }
  }, [state.currentSubject, range[0].startDate, range[0].endDate]);

  const getSubjectList = async () => {
    let { courseDetails, classDetails } = userPreference;
    let data = {
      course_id: courseDetails[0].course_id,
      class_id: classDetails.class_id,
    };

    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/user/UserACPSSubjectData",
        data
      );

      if (response.data.status === 200) {
        console.log("getSubjectList", response);
        const subjectList = response.data.data;
        setState((prevState) => ({
          ...prevState,
          topicSubjectList: subjectList,
          currentSubject: subjectList[0]?.subject,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAdaptiveCpsTestList = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    const fromDate = range[0].startDate;
    const from_date = `${fromDate.getFullYear()}-${
      fromDate.getMonth() + 1
    }-${fromDate.getDate()}`;

    const endDate = range[0].endDate;
    const end_date = `${endDate.getFullYear()}-${
      endDate.getMonth() + 1
    }-${endDate.getDate()}`;

    const data = {
      subject: state.currentSubject,
      // from_date: from_date,
      // to_date: end_date,
    };

    try {
      startLoading();
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/user/UserACPSListData",
        data,
        { headers }
      );

      console.log("getAdaptiveCpsTestList", response);

      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          questionBankTestList: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewSolution = (questionBankId) => {
    setAdaptiveCpsId(questionBankId);
    history.push("/adaptive_cps_solution");
  };

  const handleTakeTest = (sub_name) => {
    setAdaptiveCpsSubjectAction(sub_name);
    history.push({
      pathname: "/adaptive_cps_test",
      // state: { attemptCount: attemptCount },
    });
  };

  const handleChangeSubject = (event, sub_id, sub_name, icon) => {
    const selectedSubject = event.target.value;
    console.log("slectedSubject testing", selectedSubject);
    setState((prevState) => ({
      ...prevState,
      currentSubject: selectedSubject,
    }));
  };

  const handleCloseModal = () => {
    setState((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const handleShowModal = () => {
    setState((prevState) => ({
      ...prevState,
      open: true,
    }));
  };

  const handleDateRangeSubmit = () => {
    // Update the question bank tests when the date range is selected
    getAdaptiveCpsTestList();
  };

  return (
    <div className="AdaptiveCpsCustomPaperWrppr">
      <div className="AdaptiveCpsCustomPaperInrWrppr">
        <div className="content_wrapper">
          <div className="left_side_content_wrapper">
            <SubjectSelection
              topicSubjectList={state.topicSubjectList}
              currentSubject={state.currentSubject}
              handleChangeSubject={handleChangeSubject}
            />
          </div>

          <div className="right_side_content_wrappers">
            <div className="AdaptiveCpsCustomheaderAndIcon">
              {/* <div className="AdaptiveCpsHeader">Your Adaptive CPS</div> */}

              {/* <div className="AdaptiveCpsDate" onClick={handleShowModal}>
                <img src={images.user_activity_63} alt="date-img" />
              </div> */}
            </div>

            <div className="AdaptiveCpsCustomPaperContent">
              {!isEmpty(state.questionBankTestList) ? (
                state.questionBankTestList.map((qBank) => (
                  <CustomPaperBox
                    qBank={qBank}
                    handleViewSolution={handleViewSolution}
                    handleTakeTest={handleTakeTest}
                    key={qBank.question_bank_id}
                  />
                ))
              ) : (
                <div className="no_custom_tests_img">
                  <img src={images.user_activity_64} alt=""></img>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <DateRangeModal
        open={state.open}
        onClose={handleCloseModal}
        onDateRangeSubmit={handleDateRangeSubmit}
        range={range}
        setRange={setRange}
      /> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  // mapStateToProps implementation
  return {
    loader: state.ui.loader,
    token: state.auth.authToken,
    tokenType: state.auth.authType,
    class_id: state.auth.user.class_id,
    target_id: state.userPreference.targetDetails[0].target_id,
    userPreference: state.userPreference,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    setAdaptiveCpsSubjectAction: (payload) => {
      dispatch(adaptiveCpsActions.setAdaptiveCpsSubject(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDipatchToProps
)(AdaptiveCpsCustomPapers);
