import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import "./StoreBanner.scss";
import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";
import images from "../../../../utilities/images/images";
import Navbar from "../../Navbar/Navbar";

export const StoreBanner = (props) => {
  const { setIsLoginOpen, isLoginOpen } = props;
  const { target_id, class_id } = useParams();
  const [data, setData] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (target_id && class_id) {
          const response = await axios.get(
            `https://learning.motion.ac.in/motioneducation/api/user/package_list_web?target_id=${target_id}&class_id=${class_id}`
          );
          setData(response.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [target_id, class_id]);

  const handlePurchaseInitiation = () => {
    setIsLoginOpen(!isLoginOpen);
  };

  const handleViewDetailsClick = (packageId) => {
    history.push(`/packages-overview/${packageId}`);
  };

  return (
    <Fragment>
      <Navbar setIsLoginOpen={setIsLoginOpen} isLoginOpen={isLoginOpen} />

      <div className="store-details-inner-wrapper">
        <div className="content-and-btn-wrapper">
          <div className="content">
            <div className="heading">{data?.target_details?.target}Courses</div>
            <div className="sub-heading">
              {data?.class_name?.class} Class {data?.target_details?.target} all
              courses{" "}
            </div>
          </div>
          <div className="target-icon">
            <img src={data?.target_details?.target_icon} alt="target_icon" />
          </div>
        </div>
      </div>
      <div className="landing-page-package_offering_component_inner_wrapper">
        {!isEmpty(data?.package)
          ? data?.package
              .filter((packageItem) =>
                packageItem.category_id.some((category) => category.category_id)
              )
              .map((element) => {
                return (
                  <div className="package_outer" key={element.package_id}>
                    <div className="package_name_banner">
                      <img src={element.icon} alt="package banner" />
                      <p
                        style={{
                          backgroundColor: element.tag_background,
                          color: element.tag_textcolor,
                        }}
                      >
                        {element.thumbnail_tag}
                      </p>
                      <h1>{element.package_name}</h1>
                    </div>
                    <p className="package_description">{element.description}</p>
                    <div className="package_flex d-flex justify-content-between">
                      <div className="duration_div">
                        <div className="d-flex mb-2">
                          <img
                            src={images.user_activity_calendar}
                            alt="calender"
                          />
                          <p>{element.duration}</p>
                        </div>
                        {element.course_benefits_count !== 0 && (
                          <div className="d-flex">
                            <img src={images.new_store_tick} alt="tick" />
                            <p>{element.course_benefits_count}+ benefits</p>
                          </div>
                        )}
                      </div>
                      {!(
                        parseFloat(element.amount) -
                          parseFloat(element.discount) ===
                        parseFloat(element.amount)
                      ) && (
                        <div className="discount_div d-flex align-items-center">
                          <img src={images.store_offer} alt="offer" />
                          <p>
                            <span>
                              {Math.round(
                                (parseFloat(element.discount) /
                                  parseFloat(element.amount)) *
                                  100
                              )}
                              %
                            </span>{" "}
                            Off
                          </p>
                        </div>
                      )}
                      <div className="price_div">
                        <p className="final_amount">
                          ₹
                          {parseFloat(element.amount) -
                            parseFloat(element.discount)}
                        </p>
                        {!(
                          parseFloat(element.amount) -
                            parseFloat(element.discount) ===
                          parseFloat(element.amount)
                        ) && (
                          <p className="initial_amount">
                            ₹
                            <span className="text-decoration-line-through">
                              {parseFloat(element.amount)}
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="package_button_div">
                      <button
                        className="details"
                        onClick={() =>
                          handleViewDetailsClick(element.package_id)
                        }
                      >
                        View Details
                      </button>
                      {!element.is_subscribe && (
                        <button
                          onClick={handlePurchaseInitiation}
                          className="buy"
                        >
                          Buy Now
                        </button>
                      )}
                    </div>
                  </div>
                );
              })
          : "No Courses available"}
      </div>
    </Fragment>
  );
};
