import React, { useState, useEffect } from "react";

import "./AllTestsPerformanceReportTabel.scss";
import axios from "axios";
import { isEmpty } from "../../../custom_utilities/custom_useful_functions";
import { connect } from "react-redux";

const AllTestsPerformanceReportTabel = (props) => {
  let {
    startLoading,
    finishLoading,
    loader,
    LoaderPopUp,
    parentUserId,
  } = props;

  let [state, setState] = useState({
    allTestsPerformanceReport: {},
  });

  let { test_details } = state.allTestsPerformanceReport;

  useEffect(() => {
    getAllTestsPerformanceReport();
  }, []);

  const getAllTestsPerformanceReport = async () => {
    let data = {
      user_id: parentUserId,
    };

    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/StudentOverallTestReport",
        data
      );

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          allTestsPerformanceReport: response.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderTabel = () => {
    if (state.allTestsPerformanceReport.type == "JEE") {
      return (
        <div className="allTestsPerformanceReport">
          <div className="container">
            <table>
              <tr
                className="table_topHeadings"
                style={{ borderBottom: "1.7px solid #565656" }}
              >
                <th
                  colSpan="3"
                  style={{
                    background: "#838383",
                    borderTopLeftRadius: "10px",
                    borderRight: "1.7px solid #565656",
                  }}
                >
                  Test Details
                </th>
                <th
                  colSpan="6"
                  style={{
                    background: "##FFE7BA",
                    color: "#121212",
                    borderRight: "1.7px solid #565656",
                  }}
                >
                  Physics
                </th>
                <th
                  colSpan="6"
                  style={{
                    background: "#FEA4C4",
                    borderRight: "1.7px solid #565656",
                  }}
                >
                  Chemistry
                </th>
                <th
                  colSpan="6"
                  style={{
                    background: "#849FFF",
                    borderRight: "1.7px solid #565656",
                  }}
                >
                  Maths
                </th>

                <th
                  colSpan="10"
                  style={{
                    background: "#FF9393",
                    borderTopRightRadius: "10px",
                  }}
                >
                  Overall
                </th>
              </tr>

              <tr
                className="tablebtmHeadings"
                style={{ borderBottom: "1.7px solid #565656" }}
              >
                <td>Test ID</td>
                <td>Type</td>
                <td className="border_right">Date</td>
                <td>P</td>
                <td>MM</td>
                <td>%</td>
                <td>R</td>
                <td>H</td>
                <td className="border_right">A</td>
                <td>C</td>
                <td>MM</td>
                <td>%</td>
                <td>R</td>
                <td>H</td>
                <td className="border_right">A</td>

                <td>M</td>
                <td>MM</td>
                <td>%</td>
                <td>R</td>
                <td>H</td>
                <td className="border_right">A</td>

                <td>OM</td>
                <td>MM</td>
                <td>%</td>
                <td>CR</td>
                <td>CRS</td>
                <td>AIR</td>
                <td>AIS</td>
                <td>H</td>
                <td>A</td>
                <td>AR</td>
              </tr>

              {!isEmpty(test_details)
                ? test_details.length
                  ? test_details.map((element, index) => {
                      return (
                        <tr key={index} className="tableCells">
                          <td className="testDetails">{element.test_id}</td>
                          <td className="testDetails">{element.type}</td>
                          <td className="testDetails testDetailsL">
                            {element.date}
                          </td>
                          <td className="physics">
                            {element.subjects?.Physics?.marks}
                          </td>
                          <td className="physics">
                            {" "}
                            {element.subjects?.Physics?.max_marks.toFixed(0)}
                          </td>
                          <td className="physics">
                            {" "}
                            {element.subjects?.Physics?.percentage.toFixed(2)}
                          </td>
                          <td className="physics">
                            {element.subjects?.Physics?.rank}
                          </td>
                          <td className="physics">
                            {
                              element.subjects?.Physics?.highest_mark
                                .physics_marks
                            }
                          </td>
                          <td className="physics physicsL">
                            {element.subjects?.Physics?.avg}
                          </td>

                          <td className="chemistry">
                            {element.subjects?.Chemistry?.marks}
                          </td>
                          <td className="chemistry">
                            {element.subjects?.Chemistry?.max_marks.toFixed(2)}
                          </td>
                          <td className="chemistry">
                            {element.subjects?.Chemistry?.percentage.toFixed(2)}
                          </td>
                          <td className="chemistry">
                            {element.subjects?.Chemistry?.rank}
                          </td>
                          <td className="chemistry">
                            {" "}
                            {
                              element?.subjects?.Chemistry?.highest_mark
                                .chemistry_marks
                            }
                          </td>
                          <td className="chemistry chemistryL">
                            {element.subjects?.Chemistry?.avg}
                          </td>

                          <td className="maths">
                            {element.subjects?.Maths?.marks}
                          </td>
                          <td className="maths">
                            {element.subjects?.Maths?.max_marks.toFixed(2)}
                          </td>
                          <td className="maths">
                            {element.subjects?.Maths?.percentage.toFixed(2)}
                          </td>
                          <td className="maths">
                            {element.subjects?.Maths?.rank}
                          </td>
                          <td className="maths">
                            {element.subjects?.Maths?.highest_mark?.maths_marks}
                          </td>
                          <td className="maths mathsL">
                            {element.subjects?.Maths?.avg}
                          </td>

                          <td className="overAll">
                            {element.overallsummary?.obtained_marks}
                          </td>
                          <td className="overAll">
                            {element.overallsummary?.max_marks}
                          </td>
                          <td className="overAll">
                            {element.overallsummary?.percentage}
                          </td>
                          <td className="overAll">
                            {element.overallsummary?.rank}
                          </td>
                          <td className="overAll">
                            {element.overallsummary?.stud_count}
                          </td>
                          <td className="overAll">
                            {element.overallsummary?.all_india_rank}
                          </td>
                          <td className="overAll">
                            {element.overallsummary?.tot_stud_india}
                          </td>
                          <td className="overAll">
                            {element.overallsummary?.high_mark?.total_marks}
                          </td>
                          <td className="overAll">
                            {element.overallsummary?.avg}
                          </td>
                          <td className="overAll">
                            {element.overallsummary?.expectrank}
                          </td>
                        </tr>
                      );
                    })
                  : null
                : null}
            </table>
          </div>
          {loader && <LoaderPopUp />}
        </div>
      );
    } else {
      return (
        <div class="scrollmenu">
          <div className="allTestsPerformanceReportConent">
            <div className="container">
              <table>
                <tr className="table_topHeadings">
                  <th
                    colSpan="3"
                    style={{
                      background: "#6A6A6A",
                      borderTopLeftRadius: "10px",
                      borderRight: "1.7px solid #565656",
                    }}
                  >
                    Test Details
                  </th>
                  <th
                    colSpan="6"
                    style={{
                      background: "#00AFB9",
                      borderRight: "1.7px solid #565656",
                    }}
                  >
                    Physics
                  </th>
                  <th
                    colSpan="6"
                    style={{
                      background: "#6C976B",
                      borderRight: "1.7px solid #565656",
                    }}
                  >
                    Chemistry
                  </th>

                  <th
                    colSpan="6"
                    style={{
                      background: "#816B97",
                      borderRight: "1.7px solid #565656",
                    }}
                  >
                    Zoology
                  </th>

                  <th
                    colSpan="6"
                    style={{
                      background: "#EC6F64",
                      borderRight: "1.7px solid #565656",
                    }}
                  >
                    Botony
                  </th>

                  <th
                    colSpan="10"
                    style={{
                      background: "#FF933A",
                      borderTopRightRadius: "10px",
                    }}
                  >
                    Overall
                  </th>
                </tr>
                <tr className="tablebtmHeadings">
                  <td>Test ID</td>
                  <td>Type</td>
                  <td className="border_right">Date</td>
                  <td>P</td>
                  <td>MM</td>
                  <td>%</td>
                  <td>R</td>
                  <td>H</td>
                  <td className="border_right">A</td>
                  <td>C</td>
                  <td>MM</td>
                  <td>%</td>
                  <td>R</td>
                  <td>H</td>
                  <td className="border_right">A</td>

                  <td>Z</td>
                  <td>MM</td>
                  <td>%</td>
                  <td>R</td>
                  <td>H</td>
                  <td className="border_right">A</td>

                  <td>B</td>
                  <td>MM</td>
                  <td>%</td>
                  <td>R</td>
                  <td>H</td>
                  <td className="border_right">A</td>

                  <td>OM</td>
                  <td>MM</td>
                  <td>%</td>
                  <td>CR</td>
                  <td>CRS</td>
                  <td>AIR</td>
                  <td>AIS</td>
                  <td>H</td>
                  <td>A</td>
                  <td>AR</td>
                </tr>

                {!isEmpty(test_details)
                  ? test_details.length
                    ? test_details.map((element, index) => {
                        return (
                          <tr key={index} className="tableCells">
                            <td className="testDetails">{element.test_id}</td>
                            <td className="testDetails">{element.type}</td>
                            <td className="testDetails testDetailsL">
                              {element.date}
                            </td>
                            <td className="physics">
                              {element.subjects?.Physics?.marks}
                            </td>
                            <td className="physics">
                              {" "}
                              {element.subjects?.Physics?.max_marks.toFixed(0)}
                            </td>
                            <td className="physics">
                              {" "}
                              {element.subjects?.Physics?.percentage.toFixed(2)}
                            </td>
                            <td className="physics">
                              {element.subjects?.Physics?.rank}
                            </td>
                            <td className="physics">
                              {
                                element.subjects?.Physics?.highest_mark
                                  ?.physics_marks
                              }
                            </td>
                            <td className="physics physicsL">
                              {element.subjects?.Physics?.avg}
                            </td>

                            <td className="chemistry">
                              {element.subjects?.Chemistry?.marks}
                            </td>
                            <td className="chemistry">
                              {element.subjects?.Chemistry?.max_marks.toFixed(
                                2
                              )}
                            </td>
                            <td className="chemistry">
                              {element.subjects?.Chemistry?.percentage.toFixed(
                                2
                              )}
                            </td>
                            <td className="chemistry">
                              {element.subjects?.Chemistry?.rank}
                            </td>
                            <td className="chemistry">
                              {" "}
                              {
                                element.subjects?.Chemistry?.highest_mark
                                  ?.chemistry_marks
                              }
                            </td>
                            <td className="chemistry chemistryL">
                              {element.subjects?.Chemistry?.avg}
                            </td>

                            <td className="zoology">
                              {element.subjects?.Zoology?.marks}
                            </td>
                            <td className="zoology">
                              {element.subjects?.Zoology?.max_marks.toFixed(2)}
                            </td>
                            <td className="zoology">
                              {element.subjects?.Zoology?.percentage.toFixed(2)}
                            </td>
                            <td className="zoology">
                              {element.subjects?.Zoology?.rank}
                            </td>
                            <td className="zoology">
                              {
                                element.subjects?.Zoology?.highest_mark
                                  ?.zoology_marks
                              }
                            </td>
                            <td className="zoology zoologyL">
                              {element.subjects?.Zoology?.avg}
                            </td>

                            <td className="botony">
                              {element.subjects?.Botony?.marks}
                            </td>
                            <td className="botony">
                              {element.subjects?.Botony?.max_marks.toFixed(2)}
                            </td>
                            <td className="botony">
                              {element.subjects?.Botony?.percentage.toFixed(2)}
                            </td>
                            <td className="botony">
                              {element.subjects?.Botony?.rank}
                            </td>
                            <td className="botony">
                              {
                                element.subjects?.Botony?.highest_mark
                                  ?.botany_marks
                              }
                            </td>
                            <td className="botony botonyL">
                              {element.subjects?.Botony?.avg}
                            </td>

                            <td className="overAll">
                              {element.overallsummary?.obtained_marks}
                            </td>
                            <td className="overAll">
                              {element.overallsummary?.max_marks}
                            </td>
                            <td className="overAll">
                              {element.overallsummary?.percentage}
                            </td>
                            <td className="overAll">
                              {element.overallsummary?.rank}
                            </td>
                            <td className="overAll">
                              {element.overallsummary?.stud_count}
                            </td>
                            <td className="overAll">
                              {element.overallsummary?.all_india_rank}
                            </td>
                            <td className="overAll">
                              {element.overallsummary?.tot_stud_india}
                            </td>
                            <td className="overAll">
                              {element.overallsummary?.high_mark.total_marks}
                            </td>
                            <td className="overAll">
                              {element.overallsummary?.avg}
                            </td>
                            <td className="overAll">
                              {element.overallsummary?.expectrank}
                            </td>
                          </tr>
                        );
                      })
                    : null
                  : null}
              </table>
            </div>
            {loader && <LoaderPopUp />}
          </div>
        </div>
      );
    }
  };

  return <div>{renderTabel()}</div>;
};

const mapStateToProps = (state) => {
  return {
    parentUserId: state.parentrootReducer.parentAuthReducer.userId,
  };
};

export default connect(mapStateToProps, null)(AllTestsPerformanceReportTabel);
