import React, { useState, useEffect } from "react";
import "./SingleQuestionDetails.scss";
import images from "../../../../../../utilities/images/images";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";

const SingleQuestionDetails = (props) => {
  let {
    singleQuestionData,
    handleNextBtn,
    handlePrevBtn,
    history,
    page,
    plannerTestId,
    paperId,
    currentSubject,
  } = props;

  const [isNextDisabled, setIsNextDisabled] = useState(false);

  useEffect(() => {
    // Whenever the singleQuestionData changes, check if the Next button should be disabled
    setIsNextDisabled(page === singleQuestionData.total);
  }, [singleQuestionData, page]);

  const renderSingleQuestion = (element, index) => {
    return (
      <p
        key={index}
        dangerouslySetInnerHTML={{
          __html: element.q_text,
        }}
      ></p>
    );
  };

  const handleRedirect = (qId) => {
    history.push({
      pathname: "/questions-view",
      state: {
        qid: qId,
        plannerTestId: plannerTestId,
        parentPaperId: paperId,
        currentSubject: currentSubject,
      },
    });
  };

  return (
    <div className="box">
      {!isEmpty(singleQuestionData)
        ? singleQuestionData.data.length
          ? singleQuestionData.data.map((element, index) => {
              return (
                <div
                  className={`card_content ${
                    element.is_correct === "WRONG"
                      ? "cardBgChange"
                      : "card_content"
                  }`}
                >
                  <div className="top">
                    <button className={`btn prevBtn ${page == 1 ? "": "active" }`} onClick={handlePrevBtn}>
                    <i class="fas fa-chevron-left"></i>
                    </button>
                    <div className="questionbox">
                      <div className="tp">
                        <div className="left">
                          <div className="questionsNum">
                            Questions
                            <span>
                              ({page}/{singleQuestionData.total})
                            </span>
                          </div>
                          <div className="isqstncrctOrwrng">
                            {element.is_correct === "WRONG" ? (
                              <div className="is_wrong">
                                <div className="img">
                                  <img
                                    src={images.user_activity_62}
                                    alt="img"
                                  />
                                </div>
                                <div className="is_wrongText">
                                  {element?.is_correct &&
                                    element.is_correct.charAt(0) +
                                      element.is_correct.slice(1).toLowerCase()}
                                </div>
                              </div>
                            ) : (
                              <div className="is_correct">
                                <div className="img">
                                  <img
                                    src={images.user_activity_53}
                                    alt="img"
                                  />
                                </div>
                                <div className="is_correctText">
                                  {element?.is_correct &&
                                    element.is_correct.charAt(0) +
                                      element.is_correct.slice(1).toLowerCase()}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        <div
                          className="right"
                          onClick={() => handleRedirect(element.qid)}
                        >
                          View Questions
                        </div>
                      </div>
                      <div className="btm">
                        {renderSingleQuestion(element, index)}
                      </div>
                    </div>
                    <button
                      className={`btn nextBtn ${page == singleQuestionData.total ? "" : "active"}`}
                      onClick={handleNextBtn}
                      // disabled={isNextDisabled}
                      disabled={page === singleQuestionData.total ? true : false}
                    >
                      <i class="fas fa-chevron-right"></i>
                    </button>
                  </div>

                  <div className="bottom">
                    <div className="basicAnalysis">
                      <div className="heading">Basic Analysis</div>
                      <div className="basicAnalysisContent">
                        <ul>
                          <li>
                            <div className="text">Level of Difficulty</div>
                            <div className="num">{element.toughness}</div>
                          </li>

                          <li>
                            <div className="text">Marks scored</div>
                            <div className="num">4</div>
                          </li>

                          <li>
                            <div className="text">Negative marks</div>
                            <div className="num">0</div>
                          </li>

                          <li>
                            <div className="text">Total students attempted</div>
                            <div className="num">56</div>
                          </li>

                          <li>
                            <div className="text">Correct/Incorrect</div>
                            <div className="num">24/32</div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="timeAnalysis">
                      <div className="heading">Time analysis</div>
                      <div className="timeAnalysisContent">
                        <ul>
                          <li>
                            <div className="text">Time Taken</div>
                            <div className="num">
                              {element.comparison_data.time_taken?.time} sec
                            </div>
                          </li>

                          <li>
                            <div className="text">Topper</div>
                            <div className="num">
                              {element.comparison_data.topper_timetaken?.time}{" "}
                              sec
                            </div>
                          </li>

                          <li>
                            <div className="text">Avg Time needed</div>
                            <div className="num">
                              {element.comparison_data.average_time} sec
                            </div>
                          </li>

                          <li>
                            <div className="text">Exact time needed</div>
                            <div className="num">8 to 12 sec</div>
                          </li>

                          <li>
                            <div className="text"></div>
                            <div className="num"></div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : null
        : null}
    </div>
  );
};

export default SingleQuestionDetails;
