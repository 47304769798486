import { Fragment, useState, useEffect } from "react";
import SignUpStepOne from "./signUp_step_one/signUp_step_one";
import SignUpStepTwo from "./signUp_step_two/signUp_step_two";
import LoaderPopUp from "../../components/loader/loader";
import axios from "axios";
import { isEmpty } from "../../custom_utilities/custom_useful_functions";
import { url_5, url_6 } from "../../custom_utilities/api_services";
import { Analytics } from "aws-amplify";
import firebase from "../../firebase";
import { connect } from "react-redux";
import uiActions from "../../redux/ui/action";
import authActions from "../../redux/auth/action";
import userPreferenceActions from "../../redux/user_preference/action";

const SignUp = (props) => {
  const [state, setState] = useState({
    step: 1,
    innerStep: 1,
    userDetails: {
      fullName: "",
    },
    userPreference: {
      target_id: "",
      class_id: "",
      course_id: "",
      batch_id: "",
    },
    classes: {},
    courses: {},
    targets: {},
    batches: {},
    firebaseToken: "",
    loginType: "Web",
  });

  let {
    loader,
    auth,
    startLoading,
    finishLoading,
    setUser,
    setAuth,
    setUserPreference,
  } = props;

  useEffect(() => {
    getFirebaseDeviceToken();
    getTargets();
  }, []);

  const getFirebaseDeviceToken = () => {
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      messaging
        .getToken()
        .then((token) => {
          setState((prevState) => ({ ...prevState, firebaseToken: token }));
        })
        .catch(() => {});
    }
  };

  const getTargets = async () => {
    try {
      const response = await axios.get(url_5);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          targets: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBack = () => {
    if (state.step === 2) {
      if (state.innerStep === 2) {
        setState((prevState) => ({
          ...prevState,
          innerStep: 1,
        }));
      } else if (state.innerStep === 1) {
        setState((prevState) => ({
          ...prevState,
          step: 1,
          userPreference: {
            ...prevState.userPreference,
            target_id: "",
          },
        }));
      }
    } else if (state.step === 1) {
    }
  };

  const handleUserDetailsInputField = (event) => {
    const { name, value } = event.target;
    if (name === "fullName") {
      setState((prevState) => ({
        ...prevState,
        userDetails: {
          ...prevState.userDetails,
          fullName: value,
        },
      }));
    }
  };

  const handleUserDetailsFormSubmit = (targetId) => {
    const { fullName } = state.userDetails;
    if (fullName && targetId) {
      setState((prevState) => ({
        ...prevState,
        step: 2,
        userPreference: {
          ...prevState.userPreference,
          target_id: targetId,
        },
      }));
    }
  };

  const handleSelectTarget = (targetId) => {
    const targets = { ...state.targets };
    const classes = targets.target.find(
      (element) => element.target_id === targetId
    );
    if (!isEmpty(classes)) {
      setState((prevState) => ({
        ...prevState,
        classes: classes,
        userPreference: {
          ...prevState.userPreference,
          target_id: targetId,
          class_id: "",
        },
        step: 2,
        innerStep: 1,
      }));
    }
    handleUserDetailsFormSubmit(targetId);
  };

  const handleSelectClass = (classId) => {
    const courses = state?.classes?.classes.find(
      (element) => element.class_id === classId
    );
    if (!isEmpty(courses)) {
      setState((prevState) => ({
        ...prevState,
        courses: courses,
        userPreference: {
          ...prevState.userPreference,
          class_id: classId,
        },
        innerStep: 2, // Go to next inner step
      }));
    }
  };

  const handleSelectCourse = (courseId) => {
    const batches = state?.courses?.course.find(
      (element) => element.course_id === courseId
    );
    if (!isEmpty(batches.batch)) {
      setState((prevState) => ({
        ...prevState,
        batches: batches,
        userPreference: {
          ...prevState.userPreference,
          course_id: courseId,
          batch_id: batches.batch[0].batch_id,
        },
      }));
      handleUserPreferenceFormSubmit(batches.batch[0].batch_id, courseId);
    } else {
      alert("Batch is not available now, Please come back later");
    }
  };

  const handleUserPreferenceFormSubmit = async (batchId, courseId) => {
    const { userDetails, userPreference, firebaseToken, loginType } = state;
    if (window.navigator.onLine) {
      if (
        userDetails.fullName &&
        userPreference.class_id &&
        userPreference.target_id
      ) {
        startLoading();
        const formData = {
          name: userDetails.fullName,
          user_target_id: userPreference.target_id,
          class_id: userPreference.class_id,
          batch_id: batchId,
          firebaseToken: firebaseToken,
          target_ids: [courseId],
          loginType: loginType,
        };
        const returnedResponse = await signUpApi(formData, auth);
        if (returnedResponse.result === 1) {
          finishLoading();
          setAuth(true);
          setUser(returnedResponse.data.apiData);
          setUserPreference({
            targetDetails: returnedResponse.data.apiData.UsertargetDetail,
            classDetails: {
              class_id: returnedResponse.data.apiData.class_id,
              class: returnedResponse.data.apiData.class,
            },
            courseDetails: returnedResponse.data.apiData.targets,
            batchDetails: {
              batch_id: returnedResponse.data.apiData.batch_id,
              batch: returnedResponse.data.apiData.batch,
            },
          });
          Analytics.record({ name: "_userauth.sign_up" });
          props.history.push({
            pathname: "/user_dashboard",
            state: { reg_mcoin: returnedResponse.data.apiData.reg_mcoin },
          });
        } else {
          finishLoading();
          alert(returnedResponse.data.message);
        }
      } else {
        alert("Please fill in the name and select a class");
      }
    } else {
      alert("Please check your internet connection");
    }
  };

  const signUpApi = async (formData, auth) => {
    try {
      const headers = {
        Authorization: `${auth.tokenType} ${auth.authToken}`,
      };
      const response = await axios.post(url_6, formData, { headers });
      if (response.status === 200) {
        if (response.data.status === 200) {
          return {
            result: 1,
            data: {
              message: response.data.message,
              apiData: response.data.data,
            },
          };
        } else {
          return { result: 0, data: { message: response.data.message } };
        }
      } else {
        return { result: 0, data: { message: "Something went wrong!!!" } };
      }
    } catch (error) {
      return { result: 0, data: { message: error.response.statusText } };
    }
  };

  const renderView = () => {
    const { step, innerStep } = state;
    if (step === 1) {
      return (
        <SignUpStepOne
          userDetails={state.userDetails}
          targets={state.targets}
          handleUserDetailsInputField={handleUserDetailsInputField}
          handleUserDetailsFormSubmit={handleUserDetailsFormSubmit}
          userPreference={state.userPreference}
          handleSelectTarget={handleSelectTarget}
          handleBack={handleBack}
        />
      );
    } else if (step === 2) {
      return (
        <SignUpStepTwo
          handleBack={handleBack}
          classes={state.classes}
          handleSelectClass={handleSelectClass}
          courses={state.courses}
          handleSelectCourse={handleSelectCourse}
          userPreference={state.userPreference}
          innerStep={innerStep}
        />
      );
    }
  };

  return <Fragment>{loader ? <LoaderPopUp /> : renderView()}</Fragment>;
};

const mapStateToProps = (state) => ({
  loader: state.ui.loader,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  startLoading: () => dispatch(uiActions.startLoading()),
  finishLoading: () => dispatch(uiActions.finishLoading()),
  setUser: (payload) => dispatch(authActions.setUser(payload)),
  setAuth: (payload) => dispatch(authActions.setAuth(payload)),
  setUserPreference: (payload) =>
    dispatch(userPreferenceActions.setUserPreference(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
