import { React, useState } from "react";
import "./PyqSummary.scss";
import { useLocation } from "react-router-dom";
import {
 url_143
} from "../../../../../../../../custom_utilities/api_services";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import images from "../../../../../../../../utilities/images/images";
import { isEmpty } from "../../../../../../../../custom_utilities/custom_useful_functions";
import HeaderComponent from "../../../../../../structure_components/desktop_screens/header_component/header_component";
import Summary1 from "../../../../../../../../components/canvas_charts/canvas_doughnut_chart/CpsSummary1";
import Summary2 from "../../../../../../../../components/canvas_charts/canvas_doughnut_chart/CpsSummary2";
import axios from "axios";
import { useEffect } from "react";
import LoaderPopUp from "../../../../../../../../components/loader/loader";
import { useHistory,Link } from "react-router-dom";
//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../../../redux/ui/action";
import Mobile__right_sideBar_component from "../../../../../../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";
import Mobile_header_component from "../../../../../../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileLeftSideBarComponent from "../../../../../../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";

const PyqSummary = (props) => {
  let { startLoading, finishLoading, loader, testResultData, userData, userPreference,history } = props;
  let paperId = testResultData;
  const [state, setState] = useState();
  let historys = useHistory();
  const backBtn = () => {
    historys.push("/user_dashboard");
  };
  const location = useLocation();
  useEffect(() => {
    getPyqSummary();
  }, []);
  
    const getPyqSummary = async () =>  {
    let data = {
      paper_id:testResultData.paper_id,
    }
    startLoading();
    try {
      const response = await axios.post(url_143, data);
      if (response.data.status === 200) {
        setState(response.data);
        console.log("resultdata",response.data.data);
        finishLoading();
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    } finally{
      
      finishLoading();
    }
  }
  const redirect5 = () => {
    historys.push({
      pathname: "/pyq-test-paper-solution",
      state: {
        paperId,
      },
    });
  };
  let data1 = state?.data;
  let data2 = state?.marks_analysis;
  let data3 = state?.score_analysis;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const setWidth = () => {
    setWindowWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener( 'resize',setWidth)

    return (() => {
      window.removeEventListener('resize',setWidth)
    })
  },[windowWidth])
  return (
    <>
      <div className="behaviourWrapper">
      {windowWidth <= 480 ? (
            /////for Mobile Screens////////////////////
            <>
              <MobileLeftSideBarComponent />
              <Mobile_header_component
                userPreference={userPreference}
                userData={userData}
              />
              <Mobile__right_sideBar_component history={history} />
            </>
          ) : (
                <HeaderComponent history={history} />
          )}
        <div className="summary_outer">
          <div className="summary_wrapper">
            <div className="d-flex heading_div">
              <Link className="backBtn" to={{
                      pathname: "/pyq",
                      state: { tab: "pyq" }
                  }}>
                      <img src={images.m2_backBtn} alt="backBtn" />
                    </Link>
              <h3>Test Summary</h3>
            </div>
            <div className="cards_wrapper">
              <div className="summary_card">
                <p>Total Questions</p>
                <p>{data1?.total_questions}</p>
              </div>
              <div className="summary_card">
                <p>Total Marks</p>
                <p>{data1?.total_marks}</p>
              </div>
              <div className="summary_card">
                <p>Correct Answers</p>
                <p>{data1?.correct_answer}</p>
              </div>
              <div className="summary_card">
                <p>Incorrect Answer</p>
                <p>{data1?.incorrect_answer}</p>
              </div>
              <div className="summary_card">
                <p>Score Percentage</p>
                <p>{`${data1?.score_percentage}%`}</p>
              </div>
            </div>
          </div>
          <div className="button_div">
            <button onClick={() => {
              history.push({pathname: "/pyq", state: {tab: "pyq"}})
            }}>Back to Tests</button>
            <button onClick={redirect5}>View Solutions</button>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
     testResultData : state.pyqTestsData.pyqResultData,
     userData: state.auth.user,
     userPreference: state.userPreference,

  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PyqSummary);
